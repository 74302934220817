import React from "react";
import { useNavigate } from "react-router-dom";
import { Form, Alert, Button, Col, Row, FloatingLabel, Table } from "react-bootstrap";
import { format } from 'date-fns';

import Mensagem from "../../../Components/Mensagem";
import { MyContext } from "../../../Context/MyContext";
import api from "../../../Api/apiAxios";

import styles from "./ContratosCancelados.module.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/js/src/collapse.js";
import { ExpandableTable } from "../../../Components/ExpandableTable";
import Loading from "../../../Components/Loading";
import { TableItemName } from "../../../Components/TableItemName";
import { PedidosContext } from "../../../Context/PedidosContext";

export function ContratosCancelados() {
    const navigate = useNavigate();

    const nivelUsuario = window.localStorage.getItem('nivelUsuario');  
    const idDistribuidor = window.localStorage.getItem('distribuidorId');
    
    const { setListaGlobalClientes, setListaGlobalContratos, setDistribuidorIdGlobal } = React.useContext(MyContext);
    const { motivosCancelamento } = React.useContext(PedidosContext);

    const [dataInicialRelatorio, setDataInicialRelatorio] = React.useState('');
    const [dataFinalRelatorio, setDataFinalRelatorio] = React.useState('');
    const [mensagem, setMensagem] = React.useState(null);
    const [distribuidores, setDistribuidores] = React.useState([]);
    const [distribuidorSelecionado, setDistribuidorSelecionado] = React.useState('');
    const [registros, setRegistros] = React.useState('');
    const [tipoMensagem, setTipoMensagem] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const motivosMapeados = motivosCancelamento.reduce((acc, motivo) => {
      acc[motivo.id] = motivo.descricaoMotivo;
      return acc;
    }, {});
    
    React.useEffect(() =>{
        setListaGlobalClientes(null);
        setListaGlobalContratos(null);
        setDistribuidorIdGlobal('');

        const data = new Date();
      
        const ano = data.getFullYear();
        const mes = String(data.getMonth() + 1).padStart(2,'0');       
        setDataInicialRelatorio(ano + "-" + mes);
        setDataFinalRelatorio(ano + "-" + mes);

        async  function pesquisarDistribuidores() { 
            setLoading(true);                  

            if  (nivelUsuario !== "1") {
                 const resp = await api.get(`/Distribuidor/${idDistribuidor}`);  
                 const novoArray = []; 
                 novoArray.unshift(resp.data);
                 setDistribuidores(novoArray);            
            } else {
                   const response = await api.get('/Distribuidor');
                   const registrosFiltrados = response.data.filter((distribuidor) => 
                         distribuidor.statusEmpresa === 1);          
                   registrosFiltrados.sort((a,b) => a.razaoSocial.localeCompare(b.razaoSocial));

                   var todos = {
                    id: 9999,
                    razaoSocial: '*** TODOS ***',
                   }
                   registrosFiltrados.push(todos);
                   
                   setDistribuidores(registrosFiltrados);  

            }    
                   
            setLoading(false);       
        }   
        pesquisarDistribuidores();
    }, [idDistribuidor, nivelUsuario, setDistribuidorIdGlobal, setListaGlobalClientes, setListaGlobalContratos]);

    function getValueDistribuidor(ev) {
        setDistribuidorSelecionado('');
        setRegistros('');
        
        const idSelected = ev.target.children[ev.target.selectedIndex].getAttribute('id');
        setDistribuidorSelecionado(idSelected);   
    }

    const columns = [{
            title: 'Revenda',
            renderCell: (item) => item.razaoSocialDistribuidor,
        },
        {
            title: 'Quantidade',
            renderCell: (item) => (item.totalCancelamentosAcesso + item.totalCancelamentosFolha + item.totalCancelamentosPonto),
        },
    ];

    let valorTotalGeral = 0;
    let quantidadeTotalGeral = 0;

    if (registros) {
        valorTotalGeral = 'R$ ' + registros.reduce( (total, atual) => total + atual.listaContratos.reduce((total, atual) => total + atual.valor, 0), 0).toFixed(2);
        quantidadeTotalGeral = registros.reduce((total, item) => total + (item.totalCancelamentosAcesso + item.totalCancelamentosFolha + item.totalCancelamentosPonto), 0);
    }

    const totalQuantityItem = {totalTitle: 'Total', emptyValue: '', totalValue: quantidadeTotalGeral}
    const totalValueItem = {totalTitle: 'Valor total', emptyValue: '', totalValue: valorTotalGeral}

    const renderExpandedRow = (item) => {
        item.listaContratos.sort(function compare(a, b) {
            var dateA = new Date(a.dataCancelamento);
            var dateB = new Date(b.dataCancelamento);
            return dateB - dateA;
          });

        return (
            <Table className={styles.contratosCancelados} hover responsive>
                <thead className={styles.tableHeader} style={{backgroundColor: 'ghostwhite'}}>
                    <tr>
                        <th>Data de Cancelamento</th>
                        <th>Cliente</th>
                        <th>Sistemas</th>
                        <th>NS</th>
                        <th>Data de Ativação</th>
                        <th>Tipo de Contrato</th>
                        <th>Motivo do Cancelamento</th>
                        <th>Valor</th>
                    </tr>
                </thead>
                <tbody className={styles.registros}>
                    {item.listaContratos.map(item => 
                        <tr>
                            <td>{format(item.dataCancelamento, 'dd/MM/yyyy')}</td>
                            <TableItemName title={item.razaoSocialCliente} />
                            <td>{item.sistema}</td>
                            <td>{item.numeroSerie}</td>
                            <td>{format(item.dataAtivacao, 'dd/MM/yyyy')}</td>
                            <td>{item.tipoContrato}</td>
                            <td>{motivosMapeados[item.motivoCancelamentoOpcao] || '-'}</td>
                            <td>R$ {item.valor.toFixed(2)}</td>
                        </tr>
                    )}
                    <tr>
                        <td style={{fontWeight: 'bold'}}>Total</td>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td style={{fontWeight: 'bold'}}>R$ {item.listaContratos.reduce((total, atual) => total + atual.valor, 0).toFixed(2)}</td>
                    </tr>
                </tbody>
            </Table>
        );
    }

    async function handleSubmit(e) {
        e.preventDefault();
        setRegistros('');
        
        if (distribuidorSelecionado.length === 0) {
            setMensagem("Selecione a Revenda");
            setTipoMensagem("danger");

            return
        } else if (dataInicialRelatorio.length === 0 || dataFinalRelatorio.length === 0) {
            setMensagem("Selecione o período desejado");
            setTipoMensagem("danger");

            return
        }

        try {
            setLoading(true);
            setMensagem('');

            const resp = await api.get(`/Contrato/retornar-contratos-cancelados?id=${distribuidorSelecionado}&periodoInicial=${dataInicialRelatorio}&periodoFinal=${dataFinalRelatorio}`);

            if (resp.data.length > 0) {
                let registrosExpandiveis = resp.data.map(reg => {
                    if ((reg.totalCancelamentosAcesso + reg.totalCancelamentosFolha + reg.totalCancelamentosPonto) > 0) {
                        reg = {...reg, isExpandable: true}
                    } else {
                        reg = {...reg, isExpandable: false}
                    }
                    return reg;
                })

                setRegistros(registrosExpandiveis);
            } else {
                setMensagem("Não foram encontrados registros para o período selecionado.");
                setTipoMensagem("danger");
            }
        } catch (e) {
            setMensagem("Não foi possível gerar o relatório - Erro: " + e);
            setTipoMensagem("danger");
            console.log(e);
        } finally {
            setLoading(false);
        }
    }

    if  (loading) return <Loading />

    return (
        <section className={styles.form}>
            {mensagem && <Mensagem mensagem={mensagem} setMensagem={setMensagem} variant={tipoMensagem} /> }
            
            <Alert style={{background: 'ghostwhite', color: 'black'}} >
                <Alert.Heading style={{textAlign: 'center', fontSize: 'large'}} >
                    Contratos Cancelados por Período
                </Alert.Heading> 

                <br />

                <Row>
                    <Col md='2' />
                    <Col md='8'>
                        <FloatingLabel label="Selecione a Revenda">
                            <Form.Select onChange={getValueDistribuidor} value={distribuidorSelecionado} className=" form-select-control">
                                <option />                               
                                {distribuidores.map((distribuidor) => (
                                    <option 
                                        key={distribuidor.id} 
                                        id={distribuidor.id}
                                        value={distribuidor.id}
                                    >
                                        {distribuidor.razaoSocial}
                                    </option>
                                ))}
                            </Form.Select>
                        </FloatingLabel>    
                    </Col>
                </Row>
                <br />

                <Row className="g-2" style={{marginBottom: '1rem'}}>
                    <Col md='2' />
                    <Col md='4'>
                        <Form.Label style={{fontWeight: 'normal', textAlign: 'left'}} >
                            Período Inicial
                        </Form.Label>                       
                            <Form.Control 
                                type="month" 
                                placeholder="Periodo" 
                                value={dataInicialRelatorio} 
                                style={{alignSelf: 'left'}}         
                                onChange={({ target }) => setDataInicialRelatorio(target.value)}
                            />
                    </Col>

                    <Col md='4' >
                        <Form.Label style={{fontWeight: 'normal', textAlign: 'left'}} >
                            Período Final
                        </Form.Label>                       
                        <Form.Control 
                            type="month" 
                            placeholder="Periodo" 
                            value={dataFinalRelatorio} 
                            style={{alignSelf: 'left'}}         
                            onChange={({ target }) => setDataFinalRelatorio(target.value)}
                        />
                    </ Col>
                </Row>                    
            </Alert>

            <div className={styles.areaBtn}>    
               <Button onClick={handleSubmit} variant="outline-primary" className={styles.btn} 
                        disabled={nivelUsuario === "2" || nivelUsuario === "4"}>Gerar
               </Button>               
               <Button onClick={() => navigate(-1)} variant="outline-info" className={styles.btn}>Voltar</Button>
            </div>
            {registros && 
                <>
                    <ExpandableTable
                        columns={columns}
                        items={registros}
                        renderExpandedRow={renderExpandedRow}
                        totalize={true}
                        total={totalQuantityItem}
                        anotherTotal={totalValueItem}
                    />
                </>
            } 
        </section>
    )
}