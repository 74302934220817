import React, { useEffect, useState } from "react";
import { MenuItem, Table, TableBody, TableContainer, TableHead, TableRow, Paper, Container, Grid, Switch, FormControlLabel, Button, Tooltip, IconButton } from "@mui/material";
import {  FormControlBase, FormSectionBox, FormSectionTitle, InputLabelSelect, SelectBase, TextFieldBase } from "../../styles";
import { StyledTableCell } from "../acesso/acessoIndex";
import api from "../../../../Api/apiAxios";

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Cancel, Check, Edit } from "@mui/icons-material";

export function InscricoesAdicionais({ clienteId, pedidoId }) {
  const [inscricoes, setInscricoes] = useState([]);
  const [novaInscricao, setNovaInscricao] = useState(null);
  const [editIndex, setEditIndex] = useState(null);

  const handleAddInscricao = () => {
    setNovaInscricao({
      tipoInscricao: 1,
      inscricaoAdicional: '',
      razaoSocial: '',
      status: true
    });
  };

  const handleSaveInscricao = async () => {
    setInscricoes([...inscricoes, novaInscricao]);
    setNovaInscricao(null);

    let regClienteCnpj = {
      ClienteId: clienteId,
      InscricaoAdicional: novaInscricao.inscricaoAdicional, 
      TipoInscricao: novaInscricao.tipoInscricao,           
      RazaoSocial: novaInscricao.razaoSocial,
      AtestadoEmitido: 0,
      PedidoId: 0,
      Status: novaInscricao.status ? 1 : 0,
    }  

    const ret = await api.post("ClienteCnpj/", JSON.stringify(regClienteCnpj), { headers: { 'Content-Type': 'application/json'} });

    loadInscricoes();

  };

  const handleEditInscricao = (index) => {
    setEditIndex(index);
    setNovaInscricao(inscricoes[index]);
  };

  const handleSaveEditInscricao = () => {
    const updatedInscricoes = [...inscricoes];
    updatedInscricoes[editIndex] = novaInscricao;
    setInscricoes(updatedInscricoes);
    setNovaInscricao(null);
    setEditIndex(null);

    handleSaveInscricao();
  };

  const handleCancelEditInscricao = () => {
    setNovaInscricao(null);
    setEditIndex(null);
  };

  async function loadInscricoes() {
    try {
      const resp = await api.get(`/ClienteCnpj/${clienteId}`);
      setInscricoes(resp.data);
    } catch (e) {
      console.log(e);
    }
  }
  
  useEffect(() => {
    loadInscricoes();
  }, [clienteId]);

  const isSaveDisabled = !novaInscricao?.razaoSocial || !novaInscricao?.inscricaoAdicional;

  return (
    <FormSectionBox>
      <FormSectionTitle label="Inscrições Adicionais" />
      <Container sx={{ my: 3 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Tipo Inscrição</StyledTableCell>
                <StyledTableCell>Número Inscrição</StyledTableCell>
                <StyledTableCell>Razão Social</StyledTableCell>
                <StyledTableCell>Ativo</StyledTableCell>
                <StyledTableCell>Ações</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {inscricoes.map((inscricao, index) => (
                <TableRow key={index}>
                  <StyledTableCell>{inscricao.tipoInscricao === 1 ? "CNPJ" : "CPF"}</StyledTableCell>
                  <StyledTableCell>{inscricao.inscricaoAdicional}</StyledTableCell>
                  <StyledTableCell>{inscricao.razaoSocial}</StyledTableCell>
                  <StyledTableCell>
                    <Switch checked={inscricao.status === 1} disabled />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Tooltip title='Editar'>
                      <IconButton onClick={() => handleEditInscricao(index)}>
                        <Edit color="info"/>
                      </IconButton>
                    </Tooltip>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <Button onClick={handleAddInscricao} variant="text" sx={{ height: '2.5rem', fontSize: '0.78rem', color: 'rgb(19, 35, 80)', display: 'flex', alignItems: 'end' }}>
          <AddCircleIcon sx={{ marginRight: '0.5rem' }} />
          Nova Inscrição
        </Button>
      </Container>

      {novaInscricao && (
        <Container sx={{ my: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <FormControlBase>
                <InputLabelSelect>Tipo Inscrição</InputLabelSelect>
                <SelectBase
                  value={novaInscricao.tipoInscricao}              
                  label='Tipo Inscrição'
                  disabled={editIndex !== null}
                  onChange={(e) =>
                    setNovaInscricao({ ...novaInscricao, tipoInscricao: e.target.value })
                  }
                  width="100%"
                >
                  <MenuItem value={1}>CNPJ</MenuItem>
                  <MenuItem value={2}>CPF</MenuItem>
                </SelectBase>
              </FormControlBase>
            </Grid>
            <Grid item xs={3}>
              <TextFieldBase
                value={novaInscricao.inscricaoAdicional}
                label='Número Inscrição'
                disabled={editIndex !== null}
                onChange={(e) =>
                  setNovaInscricao({ ...novaInscricao, inscricaoAdicional: e.target.value.replace(/\D/g, '') })
                }
                width="100%"
              />
            </Grid>
            <Grid item xs={3}>
              <TextFieldBase
                label='Razão Social'
                value={novaInscricao.razaoSocial}
                onChange={(e) =>
                  setNovaInscricao({ ...novaInscricao, razaoSocial: e.target.value })
                }
                width="100%"
              />
            </Grid>
            <Grid item xs={1.5} sx={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel 
                control={<Switch
                checked={novaInscricao.status}
                onChange={(e) =>
                  setNovaInscricao({ ...novaInscricao, status: e.target.checked })
                }
                />} 
                label="Ativo" 
              />
            </Grid>
            <Grid item xs={1.5} sx={{ display: 'flex', alignItems: 'center' }}>
              {editIndex === null ? (
                <Tooltip title='Salvar'>
                  <IconButton onClick={handleSaveInscricao}>
                    <Check color='success' />
                  </IconButton>
                </Tooltip>
              ) : (
                <>
                  <Tooltip title='Salvar'>
                    <IconButton onClick={handleSaveEditInscricao} disabled={isSaveDisabled}>
                      <Check color='success' />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Cancelar'>
                    <IconButton onClick={handleCancelEditInscricao}>
                      <Cancel color='error' />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </Grid>
          </Grid>
        </Container>
      )}
    </FormSectionBox>
  );
}
