import { useContext, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import GridViewIcon from '@mui/icons-material/GridView';
import SortIcon from '@mui/icons-material/Sort';

import { styled } from '@mui/material/styles';
import { PontoIndex } from './components/ponto/pontoIndex';
import { Badge, IconButton, Tooltip } from '@mui/material';
import { AcessoIndex } from './components/acesso/acessoIndex';
import { FolhaIndex } from './components/folha/folhaIndex';
import { useNavigate } from 'react-router-dom';
import Mensagem from '../../Components/Mensagem';
import { MyContext } from '../../Context/MyContext';
import { PedidosContext } from '../../Context/PedidosContext';
import { FiltrosIndex } from './components/filtrosIndex';

export function Pedidos() {
  const [pesquisa, setPesquisa] = useState('');

  const nivelUsuario = window.localStorage.getItem('nivelUsuario');

  const { mensagemGlobal, setMensagemGlobal, tipoMensagemGlobal } = useContext(MyContext);
  const { productVariant, setProductVariant, handlePesquisaParcial, selectFilter, setSelectFilter, pesquisaParcial, setPesquisaParcial, setIsLoading } = useContext(PedidosContext);

  const previousProductVariant = useRef(productVariant);

  const navigate = useNavigate();

  const handleChange = (event) => {
    setProductVariant(event.target.value);
  };

  const handleNovoPedidoClick = () => {
    const routeMap = {
      Acesso: '/pedidoacesso',
      Ponto: '/pedidoponto',
      Folha: '/pedidofolha'
    };
    navigate(routeMap[productVariant] || '/');
  };

  const handleChangePesquisa = (event) => {
    setPesquisaParcial('');
    setPesquisa(event.target.value);
  };

  const handleConsultarClick = () => {
    try {
      setIsLoading(true)
      const pesquisaFormatada = formatPesquisa(pesquisa);
      handlePesquisaParcial(pesquisaFormatada);
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  };

  const handleOpenFilterOptions = () => {
    setSelectFilter({ ...selectFilter, showModal: true });
  }

  const formatPesquisa = (pesquisa) => {
    return pesquisa.length > 6 && /^\d+$/.test(pesquisa) 
      ? formatCNPJ(pesquisa) 
      : pesquisa;
  };

  const formatCNPJ = (cnpj) => {
    if (cnpj.length === 14 && /^\d+$/.test(cnpj)) {
      return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
    }
    return cnpj;
  };

  useEffect(() => {
    if (pesquisaParcial !== '') {
      try {
        setIsLoading(true)
        setPesquisa(pesquisaParcial);
        handlePesquisaParcial(pesquisaParcial);
      } catch (e) {
        console.log(e)
      } finally {
        setIsLoading(false)
      }
    }
  }, [])

  useEffect(() => {
    if (previousProductVariant.current !== productVariant) {
      setPesquisaParcial('')
      setSelectFilter({ showModal: false, selectedFilters: 0, mostrarCancelados: false, campoSelect: null });
      previousProductVariant.current = productVariant;
    }
  }, [productVariant]);


  return (
    <Container sx={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      {mensagemGlobal && <Mensagem mensagem={mensagemGlobal} setMensagem={setMensagemGlobal} variant={tipoMensagemGlobal} /> }
      {selectFilter.showModal && <FiltrosIndex />}
      <Box sx={{ margin: '1rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '80vw', height: '100%' }}>
        <FormControl sx={{ m: 1, minWidth: 120, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', height: '100%' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <Select
              id="product-select"
              value={productVariant}
              onChange={handleChange}
              displayEmpty
              input={<OutlinedInput sx={{ 
                  fontSize: '0.95rem', 
                  borderRadius: '30px', 
                  paddingX: '0.5rem', 
                  height: '3rem',
                  border: '0.2px solid rgb(19, 35, 80)', 
                  backgroundColor: 'rgb(19, 35, 80, 0.02)',
                  marginX: '1rem'
                }} 
              />}
              renderValue={(selected) => selected ? selected : <em>Selecionar Produto</em>}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem disabled value="">
                <em>Selecionar Produto</em>
              </MenuItem>
              <MenuItem value='Acesso'>Acesso</MenuItem>
              <MenuItem value='Folha'>Folha</MenuItem>
              <MenuItem value='Ponto'>Ponto</MenuItem>
            </Select>
            <Tooltip title='Filtros'>
              <IconButton sx={{ marginRight: '1rem' }} onClick={handleOpenFilterOptions} disabled={!productVariant}>
                <Badge badgeContent={selectFilter.selectedFilters} color='primary'>
                  <SortIcon style={{ color: 'rgb(19, 35, 80)' }} />
                </Badge>                    
              </IconButton>
            </Tooltip>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
              <TextField 
                label='Pesquisa Parcial'
                value={pesquisa} 
                onChange={handleChangePesquisa}
                onBlur={() => setPesquisaParcial(pesquisa)}
                onKeyDown={(e) => { if (e.key === 'Enter') handleConsultarClick() }}
                variant='outlined' 
                size='small' 
                disabled={!productVariant}
              />
              <Button 
                variant="contained"
                onClick={handleConsultarClick}
                sx={{height: '2.5rem', fontSize: '0.78rem', marginLeft: -0.5, backgroundColor: 'rgb(19, 35, 80)', borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
              >
                <SearchIcon />
                Consultar
              </Button>
            </Box>
          </Box>
          
          <Button variant='text' sx={{height: '2.5rem', fontSize: '0.78rem', color: 'rgb(19, 35, 80)', display: 'flex', alignItems: 'end'}} onClick={handleNovoPedidoClick} disabled={!productVariant || nivelUsuario === '2' || nivelUsuario === '4'}>
            <AddCircleIcon sx={{marginRight: '0.5rem'}} />
            Novo Pedido
          </Button>
        </FormControl>
      </Box>

      <ScrollWrapper component={Paper} elevation={2}>
        <ScrollableContent>
          {productVariant ? (
            productVariant === 'Ponto' ? <PontoIndex /> : (productVariant === 'Acesso' ? <AcessoIndex /> : <FolhaIndex />)
          ) : (
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%'}}>
              <GridViewIcon sx={{ fontSize: '4rem', color: 'rgb(19, 35, 80, 0.2)'}}/>
            </Box>
          )}
        </ScrollableContent>
      </ScrollWrapper>
    </Container>
  );
}

export const ScrollWrapper = styled(Box)({
  position: 'relative',
  width: '80vw',
  minHeight: '30rem',
  height: '80vh',
  maxHeight: '90vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

export const ScrollableContent = styled(Box)({
  height: '95%',
  width: 'calc(100% + 1.2rem)',
  overflowY: 'scroll',
  paddingRight: '0.4rem',
  position: 'absolute',
  right: -18,
  '&::-webkit-scrollbar': {
    width: '12px',
  },
  '&::-webkit-scrollbar-track': {
    marginTop: '0.5rem',
    paddingLeft: '1rem',
    backgroundColor: 'rgb(19, 35, 80, 0.05)',
    borderRadius: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgb(19, 35, 80)',
    borderRadius: '6px',
    border: '3px solid transparent', 
    backgroundClip: 'content-box',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: 'rgba(19, 35, 80, 0.8)',
  },
});
