import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";

import { Box, Button, Container, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";

import { ButtonBase, FormSectionBox, FormSectionTitle } from "../../styles";

import AddCircleIcon from '@mui/icons-material/AddCircle';

import { PedidosContext } from "../../../../Context/PedidosContext";
import { StyledTableCell } from "../acesso/acessoIndex";
import Loading from "../../../../Components/Loading";
import { ModalServico } from "./modalServico";
import { ActionButtons } from "./actionButtons";
import StatusPedido from "../../../../Components/StatusPedido";
import { ModalCancelamento } from "./modalCancelamento";

export function ServicoTab({ isShow = false }) {
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const nivelUsuario = window.localStorage.getItem('nivelUsuario'); 

  const { id } = useParams();
  const { 
    fetchServicos, 
    dadosTabs, 
    modalServico, 
    setModalServico, 
    servicos, 
    setServicos, 
    tiposServicos,
    modalCancelamentoServico,
    verificarECancelarServicos
  } = useContext(PedidosContext)

  const getTipoServicoTitulo = (id) => {
    const tipoServico = tiposServicos.find(tipo => tipo.id === id);
    return tipoServico ? tipoServico.tituloTipo : '-';
  };

  useEffect(() => {
    async function loadServicos() {
      try {
        setIsLoading(true);
        const servicos = await fetchServicos(id);
        await verificarECancelarServicos(servicos)

        const servicosAtualizados = await fetchServicos(id);
        const servicosOrdenados = servicosAtualizados.sort((a, b) => new Date(b.dataInclusao) - new Date(a.dataInclusao));
        
        setServicos(servicosOrdenados);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
    loadServicos();
  }, [dadosTabs.numeroSerie, id, modalServico.show])

  if (isLoading) return <Loading />;

  return (
    <Box  sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      {modalServico.show && 
        <ModalServico />
      }

      {modalCancelamentoServico.show && 
        <ModalCancelamento />
      }
      
      <FormSectionBox>
        <FormSectionTitle label="Serviços" />
        <Container sx={{ my: 3 }}>
          {nivelUsuario === "1" &&
              <Box sx={{ display: 'flex', width: '98%', justifyContent: 'end', pb: 0.5}}>
                <Button 
                  variant='text'
                  onClick={() => setModalServico({ show: true, servico: {} })}
                  sx={{height: '2.5rem', fontSize: '0.78rem', color: 'rgb(19, 35, 80)', display: 'flex', alignItems: 'end'}}
                >
                  <AddCircleIcon sx={{marginRight: '0.5rem'}}/>
                  Novo Serviço
                </Button>
              </Box>
          } 
          <TableContainer component={Paper} sx={{ overflow: 'visible' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Data do Registro</StyledTableCell>
                  <StyledTableCell>Início do Faturamento</StyledTableCell>
                  <StyledTableCell>Valor do Serviço</StyledTableCell>
                  <StyledTableCell>Parcelas</StyledTableCell>
                  <StyledTableCell>Tipo de Serviço</StyledTableCell>
                  <StyledTableCell>Crédito</StyledTableCell>
                  <StyledTableCell>Integrar no Faturamento Anterior</StyledTableCell>
                  <StyledTableCell>Qtd. Horas</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>Ações</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {servicos?.map((servico, index) => (
                  <TableRow key={index}>
                    <StyledTableCell>{format(new Date(servico.dataInclusao), 'dd/MM/yyyy')}</StyledTableCell>
                    <StyledTableCell>{format(new Date(servico.dataPrimeiroVencimento), 'dd/MM/yyyy')}</StyledTableCell>
                    <StyledTableCell sx={{ width: '10rem' }}>R$ {(servico.valorServico).toFixed(2)}</StyledTableCell>
                    <StyledTableCell>{servico.quantidadeParcelas}</StyledTableCell>
                    <StyledTableCell>{getTipoServicoTitulo(servico.tipoServico)}</StyledTableCell>
                    <StyledTableCell>{servico.ressarcimento ? "Sim" : "Não"}</StyledTableCell>
                    <StyledTableCell>{servico.integrarMesAnterior ? "Sim" : "Não"}</StyledTableCell>
                    <StyledTableCell>{servico.quantidadeHoras ? servico.quantidadeHoras.toFixed(2) : 0.00}</StyledTableCell>
                    <StyledTableCell><StatusPedido status={servico.statusServico} isPedido isServico/></StyledTableCell>
                    <StyledTableCell><ActionButtons servico={servico} /></StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </FormSectionBox>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
        {isShow || nivelUsuario !== "1" ? (
          <ButtonBase variant="outlined" onClick={() => navigate('/pedidos')}>
            Voltar
          </ButtonBase>
        ) : (
          <>
            <ButtonBase variant="outlined" onClick={() => navigate('/pedidos')}>
              Cancelar
            </ButtonBase>
            <ButtonBase type="submit" variant="contained">
              Salvar
            </ButtonBase>	
          </>
        )}
      </Box>
    </Box>
  )
}