import React from "react";
import api from "../../Api/apiAxios";
import apiRFB from "../../Api/apiAxiosRFB";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Col, FloatingLabel, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Loading from "../../Components/Loading";
import Mensagem from "../../Components/Mensagem";
import { Search } from "react-bootstrap-icons";
import { MyContext } from "../../Context/MyContext";
import { DISTRIBUIDORES_VALIDAR_INSCRICAO } from "../../Api/apiFetch";
import { addDays, format } from 'date-fns';

import styles from "./NovoDistribuidor.module.css";

const NovoDistribuidor = () => {
    const navigate = useNavigate();

    const { mensagemGlobal, setMensagemGlobal, tipoMensagemGlobal, 
                                             setTipoMensagemGlobal } = React.useContext(MyContext);

    const [loading, setLoading] = React.useState(false);

    const tiposDistribuidor = [
        {id2: 1, descricaoTipo: 'Bma'}, 
        {id2: 2, descricaoTipo: 'Revenda'}, 
        {id2: 3, descricaoTipo: 'Integrador'}, 
     ];         

     const tiposInscricao = [
        {idins: 1, tipoIns: 'CNPJ'}, 
        {idins: 2, tipoIns: 'CPF'}, 
     ];         

    var regexEmail = /\S+@\S+\.\S+/;

    const [mensagem, setMensagem] = React.useState('');
    const [tipoMensagem, setTipoMensagem] = React.useState(false);
    const [ufs, setListaUfs] = React.useState([]);
    const [cidades, setCidades] = React.useState([]);

    const [tipoInscricao, setTipoInscricao] = React.useState('1');
    const [inscricao, setInscricao] = React.useState('');
    const [tipoDistribuidor, setTipoDistribuidor] = React.useState('');
    const [ramoAtividade, setRamoAtividade] = React.useState('');
    const [inscricaoEstadual, setInscricaoEstadual] = React.useState('');
    const [razaoSocial, setRazaoSocial] = React.useState('');
    const [apelido, setApelido] = React.useState('');
    const [uf, setUf] = React.useState('');
    const [cidade, setCidade] = React.useState('');
    const [bairro, setBairro] = React.useState('');
    const [logradouro, setLogradouro] = React.useState('');
    const [numero, setNumero] = React.useState('');
    const [cep, setCep] = React.useState('');
    const [nomeContato, setNomeContato] = React.useState('');
    const [foneContato, setFoneContato] = React.useState('');
    const [site, setSite] = React.useState('');
    const [emailContato, setEmailContato] = React.useState('');
    const [emailFinanceiro, setEmailFinanceiro] = React.useState('');
    const [emailSuporte, setEmailSuporte] = React.useState('');
    const [observacoes, setObservacoes] = React.useState('');
    const [ordemCompra, setOrdemCompra] = React.useState(false);
    const [situacao, setSituacao] = React.useState(1);
    const [dataIniProspeccao, setDataIniProspeccao] = React.useState(format(new Date(), 'yyyy-MM-dd'));
    const [dataFimProspeccao, setDataFimProspeccao] = React.useState(null);
    const [dataIniContrato, setDataIniContrato] = React.useState(format(new Date(), 'yyyy-MM-dd'));
    const [dataFimContrato, setDataFimContrato] = React.useState(null);
    const [dataIniTreinamento, setDataIniTreinamento] = React.useState(format(new Date(), 'yyyy-MM-dd'));
    const [dataFimTreinamento, setDataFimTreinamento] = React.useState(null);
    const [dataConclusao, setDataConclusao] = React.useState(format(new Date(), 'yyyy-MM-dd'));
    const [dataInativacao, setDataInativacao] = React.useState(null);

    function loadUf() {
        let url = 'https://servicodados.ibge.gov.br/';
        url = url + 'api/v1/localidades/estados';
        fetch(url)
          .then(response => response.json())
          .then(data => {        
            data.sort((a,b) => a.nome.localeCompare(b.nome));
            setListaUfs([...data]);
           });
    }

    function loadCidades(id) {
        let url = 'https://servicodados.ibge.gov.br/api/v1/';
        url = url + `localidades/estados/${id}/municipios`;
        fetch(url)
            .then(response => response.json())
            .then(data => {        
            data.sort((a,b) => a.nome.localeCompare(b.nome));
            setCidades([...data]);
            });
    }  
    
    React.useEffect(() => {
        if (uf) {
            loadCidades(uf);
        }
    }, [uf]);            


    React.useEffect(() => {
        loadUf();
    }, [])

    function getValueTipoInscricao(e) {
        const idSelected1 = e.target.children[e.target.selectedIndex].getAttribute('idins');
        setTipoInscricao(idSelected1);       
    };  

    function getValueTipoDistribuidor(e) {
        const idSelected2 = e.target.children[e.target.selectedIndex].getAttribute('id2');
        setTipoDistribuidor(idSelected2);       
    };  
  

    function pesquisarClick() { 
   
         (async () => {
            try {
                if  (Number(tipoInscricao) === 1) {

                    const onlyNumbers = inscricao.toString().replace(/[^0-9]/g,'');

                    if  (onlyNumbers.length !== 14)
                    {
                        setMensagem("CNPJ precisa ter 14 caracteres !")
                        setTipoMensagem("danger");
                        return;
                    }

                    setLoading(true);
                    const response = await apiRFB.get(`${onlyNumbers}`);     

                    setTipoInscricao("1");
                    setRazaoSocial(response.data.razao_social);
                    setEmailContato(response.data.estabelecimento.email);
                    setUf(response.data.estabelecimento.estado.sigla);
                    setCidade(response.data.estabelecimento.cidade.nome);
                    setLogradouro(response.data.estabelecimento.tipo_logradouro + " " + response.data.estabelecimento.logradouro);
                    setNumero(response.data.estabelecimento.numero);
                    setBairro(response.data.estabelecimento.bairro);
                    setCep(response.data.estabelecimento.cep);
                    setRamoAtividade(response.data.estabelecimento.atividade_principal.descricao);
                    setFoneContato(response.data.estabelecimento.ddd1 + response.data.estabelecimento.telefone1);
            
                    setLoading(false);
                }

            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
        )();    
     

    }

    async function handleSubmit(event) {
        event.preventDefault();     
        setMensagemGlobal(null);

        //#region ValidacaoForm

        if  (tipoInscricao === null) {
            setMensagem("Faltou Selecionar o Tipo da Inscrição");
            setTipoMensagem("danger");
            return;
        }

        if  (tipoDistribuidor === null) {
            setMensagem("Faltou Selecionar o Tipo de Tabela");
            setTipoMensagem("danger");
            return;
        }      

        if  (emailContato.length > 5 && !regexEmail.test(emailContato))
        {
            setMensagem("Formato do E-mail de Contato Invalido !");
            setTipoMensagem("danger");
            return;
        }

        if  (emailFinanceiro.length > 5 && !regexEmail.test(emailFinanceiro))
        {
            setMensagem("Formato do E-mail Financeiro Invalido !");
            setTipoMensagem("danger");
            return;
        }

        if  (emailSuporte.length > 5 && !regexEmail.test(emailSuporte))
        {
            setMensagem("Formato do E-mail Suporte Invalido !");
            setTipoMensagem("danger");
            return;
        }

        if  (razaoSocial.length === 0 || razaoSocial.length <5) {
            setMensagem("Razão Social não preenchida ou com numero de caracteres inferior a 5");
            setTipoMensagem("danger");
            return;
        }

        if  (uf === null || cidade === null) {
            setMensagem("Faltou Selecionar UF e/ou Cidade !");
            setTipoMensagem("danger");
            return;
        }

        const inscricaoOnlyNumbers = inscricao.toString().replace(/[^0-9]/g,'');       
        const tamanho = inscricaoOnlyNumbers.length;
        if  (tamanho > 14) {
            setMensagem("Campo Inscrição com tamanho inválido !");
            setTipoMensagem("danger");
            return;
        }
    
        if  (tipoInscricao === 2 && tamanho !== 11) {
            setMensagem("CPF inválido !");
            setTipoMensagem("danger");
            return;
        }
    
        if  (tipoInscricao === 1 && tamanho !== 14) {
            setMensagem("CNPJ inválido !");
            setTipoMensagem("danger");
            return;
        }

        const { url2, options2 } = DISTRIBUIDORES_VALIDAR_INSCRICAO(inscricaoOnlyNumbers);        
        const response = await fetch(url2, options2);       
        var retorno = await(response.json());
        if  (retorno.id > 0) {
            setMensagem("CPF/CNPJ já cadastrado, favor informar outra inscrição !");
            setTipoMensagem("danger");
            return;
        }

        //#endregion

        const dataAtual = new Date();
        let regDistribuidor = {
            Id: 0,
            TipoDistribuidor: Number(tipoDistribuidor),            
            EmailFinanceiro: emailFinanceiro,
            EmailSuporte: emailSuporte,
            Site: site,
            TipoTabela: Number(tipoDistribuidor),    
            SolicitarOrdemCompra: ordemCompra ? 1 : 0,       
            RazaoSocial: razaoSocial,
            NomeFantasia: apelido,
            Logradouro: logradouro,
            Numero: numero, 
            Bairro: bairro,
            Cidade: cidade,
            UF: uf,
            Cep: Number(cep),
            Pais: 1058,
            TipoInscricao: Number(tipoInscricao),
            NumeroInscricao: inscricaoOnlyNumbers,
            InscricaoEstadual: inscricaoEstadual,
            NomeContato: nomeContato,
            FoneContato: foneContato, 
            EmailContato: emailContato,
            RamoAtividade: ramoAtividade,
            Observacao: observacoes,
            StatusEmpresa: 1,
            DataCadastro: dataAtual,
            DataAtualizacao: dataAtual,
            DataLiberacao: dataAtual,
            Situacao: Number(situacao),
        } 
        
        const assignFormattedDate = (key, date) => {
          if (date !== null) {
            const dateWithAddedDay = addDays(date, 1);
            regDistribuidor[key] = format(dateWithAddedDay, 'yyyy-MM-dd');
          }
      };

        assignFormattedDate('dataIniProspeccao', dataIniProspeccao);
        assignFormattedDate('dataFimProspeccao', dataFimProspeccao);
        assignFormattedDate('dataIniContrato', dataIniContrato);
        assignFormattedDate('dataFimContrato', dataFimContrato);
        assignFormattedDate('dataIniTreinamento', dataIniTreinamento);
        assignFormattedDate('dataFimTreinamento', dataFimTreinamento);
        assignFormattedDate('dataConclusao', dataConclusao);
        assignFormattedDate('dataInativacao', dataInativacao);
      
        const ret = await api.post("Distribuidor/", 
                    JSON.stringify(regDistribuidor), 
              {
                  headers: { 'Content-Type': 'application/json'}
              });

        setMensagemGlobal(ret.data);
        setTipoMensagemGlobal("success");               
        navigate("/revendas");

    }    

    if  (loading) return <Loading />
    
    return (
        <section className={styles.form}>

            {mensagem && <Mensagem mensagem={mensagem} setMensagem={setMensagem} variant={tipoMensagem} /> }                                 

            <Alert style={{background: 'ghostwhite', color: 'black'}}>
            <Alert.Heading style={{textAlign: 'center'}}>Nova Revenda</Alert.Heading>        
            <br />  

            <Row className="g2" style={{marginBottom: '1rem'}}>

                <Col md='4'>
                    <FloatingLabel controlid="floatingSelectGrid1" label="Tipo Inscrição">
                    <Form.Select onChange={getValueTipoInscricao}  className=" form-select-control" >
                          <option value="" disabled></option>
                           {tiposInscricao.map((reg) => (
                                    <option key={reg.idins} 
                                            idins={reg.idins}
                                            value={reg.idins}
                                            selected={reg.idins === tipoInscricao}>{reg.tipoIns}</option>
                            ))}

                    </Form.Select>                        
                            
                    </FloatingLabel>
                </Col>            
                 
                <Col md='4'>
                     <FloatingLabel controlid="floatingSelectGrid1" label="Número Inscrição">
                        <Form.Control placeholder="Número da Inscrição" value={inscricao}
                                    onChange={({ target }) => setInscricao(target.value)}/>
                      </FloatingLabel>      
                </Col>                

                 <Col md='4' style={{ display: 'flex', alignItems: 'center'}}>
                    <div className="d-flex justify-content-start">
                        <Button onClick={pesquisarClick} variant="outline-primary"> 
                            <Search size={15} /> Pesquisar na Receita Federal
                        </Button>
                    </div>
                </Col> 

            </Row>

            <Row className="g2" style={{marginBottom: '1rem'}}>
                <Col md='4'>
                     <FloatingLabel controlid="floatingSelectGrid3" label="Razão Social">
                        <Form.Control placeholder="Razão Social" value={razaoSocial}
                                    onChange={({ target }) => setRazaoSocial(target.value)}/>
                      </FloatingLabel>      
                </Col> 

                <Col md='4'>
                     <FloatingLabel controlid="floatingSelectGrid9" label="Nome Fantasia">
                        <Form.Control placeholder="Apelido" value={apelido}
                                    onChange={({ target }) => setApelido(target.value)}/>
                      </FloatingLabel>      
                </Col>  

                <Col md='4'>
                    <FloatingLabel controlid="floatingSelectGrid2" label="Tipo Tabela">
                      <Form.Select onChange={getValueTipoDistribuidor}  className=" form-select-control" >
                              <option value="" disabled></option>
                              {tiposDistribuidor.map((reg) => (
                                      <option key={reg.id2} 
                                              id2={reg.id2}
                                              value={reg.id2}
                                              selected={reg.id2 === tipoDistribuidor}>{reg.descricaoTipo}</option>
                              ))}

                      </Form.Select>                        
                    </FloatingLabel>
                </Col>                   
            </Row>

            <Row className="g2" style={{marginBottom: '1rem'}}>
                <Col md='4'>
                     <FloatingLabel controlid="floatingSelectGrid5" label="Inscrição Estadual">
                        <Form.Control placeholder="I.E." value={inscricaoEstadual}
                                    onChange={({ target }) => setInscricaoEstadual(target.value)}/>
                      </FloatingLabel>      
                </Col>                

                <Col md='4'>
                     <FloatingLabel controlid="floatingSelectGrid4" label="Ramo de Atividade">
                        <Form.Control placeholder="Razão Social" value={ramoAtividade}
                                    onChange={({ target }) => setRamoAtividade(target.value)}/>
                      </FloatingLabel>      
                </Col>
                <Col md='4'>
                  <FloatingLabel controlid="floatingSelectGrid2" label="Estado">
                    <Form.Select onChange={({ target }) => setSituacao(target.value)} value={situacao} className=" form-select-control" >
                            <option value={1}>Prospecção</option>
                            <option value={2}>Envio de Contrato</option>
                            <option value={3}>Treinamento</option>
                            <option value={4}>Credenciado</option>
                    </Form.Select>                        
                  </FloatingLabel>
                </Col>                
            </Row>

            <Row className="g2" style={{marginBottom: '1rem'}}>
              {Number(situacao) === 1 &&
              <>
                <Col md='6'>
                  <FloatingLabel controlid="floatingInputGrid5" label="Data Início Prospecção">
                    <Form.Control value={dataIniProspeccao} type='date' onChange={({ target }) => setDataIniProspeccao(target.value)}/>
                  </FloatingLabel>
                </Col>
                <Col md='6'>
                  <FloatingLabel controlid="floatingInputGrid5" label="Data Fim Prospecção">
                    <Form.Control value={dataFimProspeccao} type='date' onChange={({ target }) => setDataFimProspeccao(target.value)}/>
                  </FloatingLabel>
                </Col>
              </>
              }
              {Number(situacao) === 2 &&
              <>
                <Col md='6'>
                  <FloatingLabel controlid="floatingInputGrid5" label="Data Início Contrato">
                    <Form.Control value={dataIniContrato} type='date' onChange={({ target }) => setDataIniContrato(target.value)}/>
                  </FloatingLabel>
                </Col>
                <Col md='6'>
                  <FloatingLabel controlid="floatingInputGrid5" label="Data de Assinatura">
                    <Form.Control value={dataFimContrato} type='date' onChange={({ target }) => setDataFimContrato(target.value)}/>
                  </FloatingLabel>
                </Col>
              </>
              }
              {Number(situacao) === 3 &&
              <>
                <Col md='6'>
                  <FloatingLabel controlid="floatingInputGrid5" label="Data Início Treinamento">
                    <Form.Control value={dataIniTreinamento} type='date' onChange={({ target }) => setDataIniTreinamento(target.value)}/>
                  </FloatingLabel>
                </Col>
                <Col md='6'>
                  <FloatingLabel controlid="floatingInputGrid5" label="Data Fim Treinamento">
                    <Form.Control value={dataFimTreinamento} type='date' onChange={({ target }) => setDataFimTreinamento(target.value)}/>
                  </FloatingLabel>
                </Col>
              </>
              }
              {Number(situacao) === 4 &&
              <>
                <Col md='6'>
                  <FloatingLabel controlid="floatingInputGrid5" label="Data de Credenciamento">
                    <Form.Control value={dataConclusao} type='date' onChange={({ target }) => setDataConclusao(target.value)}/>
                  </FloatingLabel>
                </Col>
                <Col md='6'>
                  <FloatingLabel controlid="floatingInputGrid5" label="Data de Inativação">
                    <Form.Control value={dataInativacao} type='date' onChange={({ target }) => setDataInativacao(target.value)}/>
                  </FloatingLabel>
                </Col>
              </>
              }
            </Row>                   

            <hr />                    
            <Alert.Heading style={{textAlign: 'center'}}>Endereço</Alert.Heading>        
            <br /> 

            <Row className="g2" style={{marginBottom: '1rem'}}>
                <Col md='2'>
                    <FloatingLabel controlid="floatingSelectGrid6" label="UF">
                    <Form.Select value={uf} onChange={({ target }) => setUf(target.value)}>

                        <option value="" disabled></option>
                        {ufs.map((uf, index) =>
                            <option key={index}>{uf.sigla}</option>
                        )}                   
                    </Form.Select>                              
                    </FloatingLabel>
                </Col>            

                <Col md='7'>
                    <FloatingLabel controlid="floatingSelectGridCidades" label="Cidade">
                    <Form.Select value={cidade} onChange={({ target }) => setCidade(target.value)}>

                        <option value="" disabled>Selecione a Cidade</option>
                            {cidades.map((cidade, cont) =>
                            <option key={cont}>{cidade.nome}</option>
                         )}
                   
                    </Form.Select>
                    </FloatingLabel>
                </Col>

                <Col md='3'>
                     <FloatingLabel controlid="floatingSelectGrid7" label="CEP">
                        <Form.Control placeholder="CEP" value={cep}
                                    onChange={({ target }) => setCep(target.value)}/>
                      </FloatingLabel>      
                </Col>                

            </Row>                    

            <Row className="g2" style={{marginBottom: '1rem'}}>
                <Col md='6'>
                     <FloatingLabel controlid="floatingSelectGrid8" label="Logradouro">
                        <Form.Control placeholder="Logradouro" value={logradouro}
                                    onChange={({ target }) => setLogradouro(target.value)}/>
                      </FloatingLabel>      
                </Col>                

                <Col md='2'>
                     <FloatingLabel controlid="floatingSelectGrid8" label="Numero">
                        <Form.Control placeholder="Numero" value={numero}
                                    onChange={({ target }) => setNumero(target.value)}/>
                      </FloatingLabel>      
                </Col>                

                <Col md='4'>
                     <FloatingLabel controlid="floatingSelectGrid9" label="Bairro">
                        <Form.Control placeholder="Bairro" value={bairro}
                                    onChange={({ target }) => setBairro(target.value)}/>
                      </FloatingLabel>      
                </Col>                

            </Row>   

            <hr />                    
            <Alert.Heading style={{textAlign: 'center'}}>E-mails</Alert.Heading>        
            <br /> 

            <Row className="g2" style={{marginBottom: '1rem'}}>
                <Col md='12'>
                     <FloatingLabel controlid="floatingSelectGrid13" label="Principal">
                        <Form.Control placeholder="Email Principal" value={emailContato}
                                    onChange={({ target }) => setEmailContato(target.value)}/>
                      </FloatingLabel>      
                </Col>                
            </Row>

            <Row className="g2" style={{marginBottom: '1rem'}}>
                <Col md='12'>
                     <FloatingLabel controlid="floatingSelectGrid14" label="Financeiro">
                        <Form.Control placeholder="Email Financeiro" value={emailFinanceiro}
                                    onChange={({ target }) => setEmailFinanceiro(target.value)}/>
                      </FloatingLabel>      
                </Col>                
            </Row>

            <Row className="g2" style={{marginBottom: '1rem'}}>
                <Col md='12'>
                     <FloatingLabel controlid="floatingSelectGrid14" label="Suporte">
                        <Form.Control placeholder="Email Suporte" value={emailSuporte}
                                    onChange={({ target }) => setEmailSuporte(target.value)}/>
                      </FloatingLabel>      
                </Col>                
            </Row>                 

            <hr />                    
            <Alert.Heading style={{textAlign: 'center'}}>Contato</Alert.Heading>        
            <br /> 

            <Row className="g2" style={{marginBottom: '1rem'}}>
                <Col md='4'>
                     <FloatingLabel controlid="floatingSelectGrid10" label="Nome">
                        <Form.Control placeholder="Nome Contato" value={nomeContato}
                                    onChange={({ target }) => setNomeContato(target.value)}/>
                      </FloatingLabel>      
                </Col>                

                <Col md='3'>
                     <FloatingLabel controlid="floatingSelectGrid11" label="Telefone">
                        <Form.Control placeholder="Fone Contato" value={foneContato}
                                    onChange={({ target }) => setFoneContato(target.value)}/>
                      </FloatingLabel>      
                </Col>                

                <Col md='5'>
                     <FloatingLabel controlid="floatingSelectGrid12" label="Site">
                        <Form.Control placeholder="Site Contato" value={site}
                                    onChange={({ target }) => setSite(target.value)}/>
                      </FloatingLabel>      
                </Col>                

            </Row>    

            <hr />                    
            <Alert.Heading style={{textAlign: 'center'}}>Informações Adicionais</Alert.Heading>        
            <br /> 

            <Row className="g2" style={{marginBottom: '1rem'}}>
                <Col md='12'>
                     <FloatingLabel controlid="floatingSelectGrid15" label="Observações Gerais">
                        <Form.Control placeholder="Email Financeiro" value={observacoes}
                                    onChange={({ target }) => setObservacoes(target.value)}/>
                      </FloatingLabel>      
                </Col>    
            </Row>

            <Row className="g2" style={{marginBottom: '1rem'}}>
                <Col md='6'>
                    <Form.Check 
                        type="switch"
                        id="ordem-compra"
                        label="Ordem de Compra"                      
                        onChange={() => setOrdemCompra(!ordemCompra)}
                        checked={ordemCompra}
                    />

                </Col>
            </Row>

            </Alert>

            <div className={styles.areaBtn}>                
               <Button onClick={handleSubmit} variant="outline-primary" className={styles.btn}>Confirmar</Button>                         
               <Button onClick={() => navigate(-1)} variant="outline-info" className={styles.btn}>Voltar</Button>
            </div>    

        </section>
    )

}

export default NovoDistribuidor;
