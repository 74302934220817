import React, { useEffect, useState } from "react";
import api from "../../Api/apiAxios";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

export const PedidosContext = React.createContext();

export const PedidosData = ({children}) => {
  const [productVariant, setProductVariant] = useState('');
  const [distribuidores, setDistribuidores] = useState([]);
  const [pedidosAcesso, setPedidosAcesso] = useState([]);
  const [pedidosFolha, setPedidosFolha] = useState([]);
  const [pedidosPonto, setPedidosPonto] = useState([]);
  const [modalLiberacao, setModalLiberacao] = useState({show: false, pedido: {}});
  const [modalCancelamento, setModalCancelamento] = useState({show: false, pedido: {}});
  const [modalCancelamentoContrato, setModalCancelamentoContrato] = useState({ show: false, contrato: {}})
  const [modalCancelamentoServico, setModalCancelamentoServico] = useState({ show: false, servico: {}})
  const [motivosCancelamento, setMotivosCancelamento] = useState([{ id: null, descricaoMotivo: '' }]);
  const [dadosTabs, setDadosTabs] = useState({ distribuidor: null, cliente: null, nomeFantasia: '', numeroSerie: null });
  const [contrato, setContrato] = useState({});
  const [contratos, setContratos] = useState({});
  const [selectFilter, setSelectFilter] = useState({ showModal: false, selectedFilters: 0, mostrarCancelados: false, campoSelect: null });
  const [isLoading, setIsLoading] = useState(false);
  const [modalNegociacao, setModalNegociacao] = useState({show: false, negociacao: {}});
  const [negociacoesAnteriores, setNegociacoesAnteriores] = useState([]);
  const [modalContrato, setModalContrato] = useState({show: false, contrato: {}});
  const [modalServico, setModalServico] = useState({show: false, servico: {}});
  const [servicos, setServicos] = useState([]);
  const [tiposServicos, setTiposServicos] = useState([{ id: null, tituloTipo: '' }]);
  const [pesquisaParcial, setPesquisaParcial] = useState('');
  const [pedidoLiberado, setPedidoLiberado] = useState(false);
  const [isAuth, setIsAuth] = useState(false);

  const navigate = useNavigate();

  async function fetchPedidosAcesso(mostrarCancelados = false, vertical = null, filtroSelecionado = false) {
    const nivelUsuario = window.localStorage.getItem('nivelUsuario');
    const idDistribuidor = window.localStorage.getItem('distribuidorId');

    const verticalParam = filtroSelecionado ? vertical || 0 : (selectFilter.campoSelect || 0);
    const mostrarCanceladosParam = filtroSelecionado ? mostrarCancelados : (selectFilter.mostrarCancelados || false);
    const url = `/BmaAcesso/consulta-nivel?id=${idDistribuidor}&nivel=${nivelUsuario}&mostrarCancelados=${mostrarCanceladosParam}&vertical=${verticalParam}`;
  
    try {
      const response = await api.get(url);
      await verificarECancelarPedidos(response.data);
  
      if (pesquisaParcial !== '') {
        await handlePesquisaParcial(pesquisaParcial, mostrarCanceladosParam, verticalParam, filtroSelecionado);
      } else {
        setPedidosAcesso(response.data);
      }
    } catch (error) {
      console.error("Erro ao buscar pedidos de acesso:", error);
    }
  }

  async function fetchPedidosFolha(mostrarCancelados = false, filtroSelecionado = false) {
    const nivelUsuario = window.localStorage.getItem('nivelUsuario');
    const idDistribuidor = window.localStorage.getItem('distribuidorId');

    const mostrarCanceladosParam = filtroSelecionado ? mostrarCancelados : (selectFilter.mostrarCancelados || false);

    const url = `/BmaFolha/consulta-nivel?id=${idDistribuidor}&nivel=${nivelUsuario}&mostrarCancelados=${mostrarCanceladosParam}`;
  
    try {
      const response = await api.get(url);
      await verificarECancelarPedidos(response.data);
  
      if (pesquisaParcial !== '') {
        await handlePesquisaParcial(pesquisaParcial, mostrarCanceladosParam, null, filtroSelecionado);
      } else {
        setPedidosFolha(response.data);
      }
    } catch (error) {
      console.error("Erro ao buscar pedidos de folha:", error);
    }
  }  

  async function fetchPedidosPonto(mostrarCancelados = false, plano = null, filtroSelecionado = false) {
    const nivelUsuario = window.localStorage.getItem('nivelUsuario');
    const idDistribuidor = window.localStorage.getItem('distribuidorId');

    const mostrarCanceladosParam = filtroSelecionado ? mostrarCancelados : (selectFilter.mostrarCancelados || false);
    const planoParam = filtroSelecionado ? plano : (selectFilter.campoSelect || null);
    
    const url = `/BmaPonto/consulta-nivel?id=${idDistribuidor}&nivel=${nivelUsuario}&mostrarCancelados=${mostrarCanceladosParam}` + (planoParam !== null && planoParam >= 0 ? `&plano=${planoParam}` : '');
  
    try {
      const response = await api.get(url);
      await verificarECancelarPedidos(response.data);
  
      if (pesquisaParcial) {
        await handlePesquisaParcial(pesquisaParcial, mostrarCanceladosParam, planoParam, filtroSelecionado);
      } else {
        setPedidosPonto(response.data);
      }
    } catch (error) {
      console.error("Erro ao buscar pedidos de ponto:", error);
    }
  }

  async function fetchMotivosCancelamento() {
    const response = await api.get('MotivoCancelamento/');
    setMotivosCancelamento(response.data);
  }

  async function fetchTiposServicos() {
    const response = await api.get('TipoServico/');
    setTiposServicos(response.data);
    console.log(response.data)
  }

  async function handlePesquisaParcial(pesquisa, mostrarCancelados = false, campoSelect = null, filtroSelecionado = false) {
    const nivelUsuario = window.localStorage.getItem('nivelUsuario');
    const idDistribuidor = window.localStorage.getItem('distribuidorId');

    const fetchMap = {
      'Acesso': fetchPedidosAcesso,
      'Folha': fetchPedidosFolha,
      'Ponto': fetchPedidosPonto,
    };
    
    const setPedidosMap = {
      'Acesso': setPedidosAcesso,
      'Folha': setPedidosFolha,
      'Ponto': setPedidosPonto,
    };
  
    if (!pesquisa) {
      fetchMap[productVariant]?.();
      return;
    }
  
    try {
      const response = await api.get(`/Bma${productVariant}/parametros-consulta?id=${idDistribuidor}&nivel=${nivelUsuario}&args=${pesquisa}`);
      let pedidosFiltrados = response.data;

      const baseParams = `/Bma${productVariant}/consulta-nivel?id=${idDistribuidor}&nivel=${nivelUsuario}&mostrarCancelados=${filtroSelecionado ? mostrarCancelados : selectFilter.mostrarCancelados}`;

      const verticalParam = 
        productVariant === 'Acesso' 
          ? `&vertical=${filtroSelecionado ? campoSelect || 0 : selectFilter.campoSelect || 0}` 
          : '';

      const planoParam = 
        productVariant === 'Ponto' && (campoSelect || selectFilter.campoSelect) 
          ? `&plano=${filtroSelecionado ? campoSelect : selectFilter.campoSelect}` 
          : '';

      const consultaNivelUrl = `${baseParams}${verticalParam}${planoParam}`;

      const listaFiltradaResponse = await api.get(consultaNivelUrl);
      const listaFiltrada = listaFiltradaResponse.data;

      pedidosFiltrados = pedidosFiltrados.filter(item => 
        listaFiltrada.some(filtro => filtro.id === item.id)
      );

      setPedidosMap[productVariant]?.(pedidosFiltrados);
    } catch (error) {
      console.error("Erro ao buscar pedidos:", error);
    }
  }
  
  async function verificarECancelarPedidos(pedidos) {
    const dataAtual = new Date();
    
    const pedidosParaCancelar = pedidos.filter(pedido => {
      const dataCancelamento = pedido.dataCancelamento ? new Date(pedido.dataCancelamento) : null;
      return dataCancelamento && dataCancelamento <= dataAtual && pedido.statusPedido !== 2;
    });
  
    if (pedidosParaCancelar === 0) return;

    const promises = pedidosParaCancelar.map(pedido => 
      api.put(`/Bma${productVariant}/atualizar-status-pedido?id=${pedido.id}&status=2&motivo=${pedido.motivoCancelamento}&motivoCancelamentoOpcao=${pedido.motivoCancelamentoOpcao}&dataCancelamento=${pedido.dataCancelamento}`)
        .then(() => console.log(`Pedido ${pedido.id} atualizado com sucesso!`))
        .catch(error => console.error(`Erro ao atualizar o pedido ${pedido.id}:`, error))
    );

    await Promise.all(promises);
  }

  async function verificarECancelarServicos(servicos) {
    const dataAtual = format(new Date(), 'yyyy-MM-dd');
  
    const servicosParaCancelar = servicos.filter(servico => {
      const dataCancelamento = servico.dataCancelamento ? format(new Date(servico.dataCancelamento), 'yyyy-MM-dd') : null;
      return dataCancelamento && dataCancelamento <= dataAtual && servico.statusPedido !== 2;
    });
  
    if (servicosParaCancelar.length === 0) return;

    const promises = servicosParaCancelar.map(async (servico) => {
      try {
        await api.put(`/Servico/atualizar-status-servico?id=${servico.id}&status=2&motivo=${servico.motivoCancelamento}&motivoCancelamentoOpcao=${servico.motivoCancelamentoOpcao}&dataCancelamento=${servico.dataCancelamento}`);
        console.log(`Serviço ${servico.id} atualizado com sucesso!`);
      } catch (error) {
        console.error(`Erro ao atualizar o serviço ${servico.id}:`, error);
      }
    });
  
    await Promise.all(promises);
  }
  

  async function verificarECancelarContratos(contratos) {
    const dataAtual = format(new Date(), 'yyyy-MM-dd');
    
    const contratosParaCancelar = contratos.filter(contrato => {
      const dataCancelamento = contrato.dataCancelamento ? format(new Date(contrato.dataCancelamento), 'yyyy-MM-dd') : null;
      return dataCancelamento && dataCancelamento <= dataAtual && contrato.statusContrato !== 0;
    });
    
    if (contratosParaCancelar.length === 0) return;

    const promises = contratosParaCancelar.map(async (contrato) => {
      try {
        await api.put(`/Contrato/cancelar-contrato?id=${contrato.id}&motivo=${contrato.motivoCancelamento}&motivoCancelamentoOpcao=${contrato.motivoCancelamentoOpcao}&dataCancelamento=${contrato.dataCancelamento}`);
        console.log(`Contrato ${contrato.id} atualizado com sucesso!`);
      } catch (error) {
        console.error(`Erro ao atualizar o contrato ${contrato.id}:`, error);
      }
    });

    await Promise.all(promises);
  }  

  const fetchNegociacoesAnteriores = async (contratoId) => {
    try {
      setIsLoading(true)

      const resp = await api.get(`NegociacaoContrato/contrato?idContrato=${contratoId}&tipoSistema=${productVariant}`)

      return resp.data
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  async function fetchServicos(pedidoId) {
    if (!pedidoId) return;

    try {
      setIsLoading(true)

      const resp = await api.get(`Servico/pedido?pedidoId=${pedidoId}&numeroSerie=${dadosTabs.numeroSerie}&clienteId=${dadosTabs.cliente}&distribuidorId=${dadosTabs.distribuidor}&tipoSistema=${productVariant.toLowerCase()}`);

      return resp.data;
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }      
  }

  async function fetchContratos(id) {
    let url = `Contrato/pedido?pedidoId=${id}&tipoSistema=${productVariant}`;

    if (dadosTabs.numeroSerie) {
        url += `&numeroSerie=${dadosTabs.numeroSerie}`;
    }

    if (dadosTabs.cliente) {
        url += `&clienteId=${dadosTabs.cliente}`;
    }

    const resp = await api.get(url);

    const contratoAtivo = resp.data.find(contrato => contrato.statusContrato === 1);
    const contratoMaisRecente = contratoAtivo ? contratoAtivo : resp.data.sort((a, b) => new Date(b.dataAtivacao) - new Date(a.dataAtivacao))[0];
    
    setContrato(contratoMaisRecente || null);

    const contratosOrdenados = resp.data.sort((a, b) => new Date(b.dataAtivacao) - new Date(a.dataAtivacao));
    
    return contratosOrdenados
  }

  const getDistribuidores = async () => {
    const nivelUsuario = window.localStorage.getItem("nivelUsuario");
    const idDistribuidor = window.localStorage.getItem("distribuidorId");
  
    if (!isAuth || !nivelUsuario) {
      navigate('/login');
      return;
    };
  
    try {
      const endpointUrl = nivelUsuario === "1" ? "/Distribuidor" : `/Distribuidor/${idDistribuidor}`;
      const { data } = await api.get(endpointUrl);
  
      const distribuidores = nivelUsuario === "1"
        ? data
            .filter(({ statusEmpresa }) => statusEmpresa === 1)
            .sort((a, b) => a.razaoSocial.localeCompare(b.razaoSocial))
        : [data];
  
      setDistribuidores(distribuidores);
    } catch (error) {
      console.error("Erro ao buscar distribuidores:", error);
    }
  };  

  useEffect(() => {
    getDistribuidores();
  }, [isAuth]);

  function parseNumberCurrency(value) {
    if (typeof value === 'string' && (value.includes(',') || value.includes('.'))) {
      const formattedString = value.replace(/\./g, '').replace(',', '.');
      return parseFloat(formattedString);
    }
  
    return Number(value);  
  }
  
  useEffect(() => {
    fetchMotivosCancelamento();
    fetchTiposServicos();
  }, [])

  return (
    <PedidosContext.Provider 
      value={{
        productVariant,
        modalLiberacao,
        modalCancelamento,
        pedidosAcesso,
        pedidosFolha,
        pedidosPonto,
        distribuidores,
        motivosCancelamento,
        dadosTabs,
        contrato,
        modalCancelamentoContrato,
        selectFilter,
        isLoading,
        modalNegociacao,
        negociacoesAnteriores,
        modalContrato,
        modalServico,
        servicos,
        tiposServicos,
        pesquisaParcial,
        contratos,
        modalCancelamentoServico,
        pedidoLiberado,
        isAuth, 
        setIsAuth,
        setPedidoLiberado,
        setModalCancelamentoServico,
        setContratos,
        setPesquisaParcial,
        setTiposServicos,
        setServicos,
        setModalServico,
        setModalContrato,
        setNegociacoesAnteriores,
        setModalNegociacao,
        setIsLoading,
        setSelectFilter,
        setModalCancelamentoContrato,
        setContrato,
        setDadosTabs,
        setMotivosCancelamento,
        setDistribuidores,
        setModalLiberacao,
        setModalCancelamento,
        setProductVariant,
        setPedidosAcesso,
        setPedidosFolha,
        setPedidosPonto,
        fetchPedidosFolha,
        fetchPedidosAcesso,
        fetchPedidosPonto,
        fetchMotivosCancelamento,
        fetchNegociacoesAnteriores,
        fetchServicos,
        fetchTiposServicos,
        fetchContratos,
        handlePesquisaParcial,
        verificarECancelarServicos,
        verificarECancelarContratos,
        parseNumberCurrency,
        getDistribuidores,
      }}
    >
      {children}
    </PedidosContext.Provider>
  )
}
