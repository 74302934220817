import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, FormControlLabel, Switch, MenuItem, InputLabel, Container, Grid } from '@mui/material';
import { useFormik } from 'formik';
import { format } from 'date-fns';
import * as Yup from 'yup';

import { FormControlBase, TextFieldBase, SelectBase, FormSectionTitle, InputLabelSelect } from '../../../styles';
import { PedidosContext } from '../../../../../Context/PedidosContext';
import api from '../../../../../Api/apiAxios';

const validationSchema = Yup.object({
  id: Yup.number(),
  tipoSistema: Yup.string().required('O tipo de sistema é obrigatório'),
  numeroSerie: Yup.string().required('O número de série é obrigatório'),
  inicioFaturamento: Yup.date().required('A data de início de faturamento é obrigatória').nullable(),
  distribuidor: Yup.number().required('A revenda é obrigatória'),
  cliente: Yup.number().required('O cliente é obrigatório'),
  nomeFantasia: Yup.string().required('O nome fantasia é obrigatório'),
  valorServico: Yup.number().required('O valor do serviço é obrigatório').min(0, 'O valor do serviço não pode ser negativo'),
  quantidadeParcelas: Yup.number().required('A quantidade de parcelas é obrigatória').min(1, 'Deve haver pelo menos uma parcela'),
  valorParcela: Yup.number().min(0, 'O valor da parcela não pode ser negativo'),
  credito: Yup.boolean(),
  integraFaturamentoAnt: Yup.boolean(),
  tipoServico: Yup.number().required('O tipo de serviço é obrigatório'),
  quantidadeHoras: Yup.number().min(0, 'A quantidade de horas deve ser maior ou igual a zero'),
  descricaoServico: Yup.string().required('A descrição do serviço é obrigatória').min(10, 'A descrição do serviço deve ter pelo menos 10 caracteres'),
});

export function ModalServico() {
  const idUsuario = window.localStorage.getItem('idUsuario');

  const { id } = useParams();
  const { productVariant, dadosTabs, modalServico, setModalServico, fetchTiposServicos, tiposServicos, setServicos, fetchServicos } = useContext(PedidosContext);

  const handleSave = async (values) => {
    setModalServico({ show: false, servico: {} });

    const regServico = {
      Id: formik.values.id || 0,
      PedidoId: id,
      DistribuidorId: Number(formik.values.distribuidor),
      ClienteId: Number(formik.values.cliente),
      UsuarioId: Number(idUsuario),
      NumeroSerie: formik.values.numeroSerie,
      TipoSistema: Number(formik.values.tipoSistema === 'Ponto' ? 1 : (formik.values.tipoSistema === 'Acesso' ? 2 : 3)),
      TipoServico: Number(formik.values.tipoServico),
      DescricaoServico: formik.values.descricaoServico,
      QuantidadeParcelas: Number(formik.values.quantidadeParcelas),
      StatusServico: 0,
      ValorServico: Number(formik.values.valorServico),
      DataInclusao: new Date(),
      DataLiberacao: new Date(),
      DataPrimeiroVencimento: formik.values.inicioFaturamento,
      Ressarcimento: formik.values.credito ? 1 : 0,
      QuantidadeHoras: formik.values.quantidadeHoras,
      IntegrarMesAnterior: formik.values.integraFaturamentoAnt ? 1 : 0,
    };

    let ret 

    if (modalServico.servico.id) {
      ret = await api.put("Servico/", JSON.stringify(regServico), { headers: { 'Content-Type': 'application/json' } });
    } else {
      ret = await api.post("Servico/", JSON.stringify(regServico), { headers: { 'Content-Type': 'application/json' } });
    }

    console.log(ret.data);
    const listaServicos = await fetchServicos(id)
    const servicosOrdenados = listaServicos.sort((a, b) => new Date(b.dataInclusao) - new Date(a.dataInclusao));
    setServicos(servicosOrdenados);
  };

  const formik = useFormik({
    initialValues: {
      id: modalServico.servico.id || 0,
      tipoSistema: productVariant || '',
      numeroSerie: dadosTabs.numeroSerie || '',
      inicioFaturamento: modalServico.servico.dataPrimeiroVencimento ? format(new Date(modalServico.servico.dataPrimeiroVencimento), 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd'),
      distribuidor: dadosTabs.distribuidor || '',
      cliente: dadosTabs.cliente || '',
      nomeFantasia: dadosTabs.nomeFantasia || '',
      valorServico: modalServico.servico.valorServico || '',
      quantidadeParcelas: modalServico.servico.quantidadeParcelas || '',
      valorParcela: modalServico.servico.valorParcela || '',
      credito: modalServico.servico.ressarcimento,
      integraFaturamentoAnt: modalServico.servico.integrarMesAnterior,
      descricaoServico: modalServico.servico.descricaoServico || '',
      tipoServico: modalServico.servico.tipoServico || 0,
      quantidadeHoras: modalServico.servico.quantidadeHoras || 0.00,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSave(values);
    },
  });

  useEffect(() => {
    fetchTiposServicos();
    fetchServicos();
  }, [modalServico.show])

  return (
    <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
      <Box sx={{
        backgroundColor: 'white',
        py: 3,
        px: 1,
        borderRadius: '30px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        width: '50%',
        alignSelf: 'center',
        position: 'fixed',
        top: 100,
        zIndex: 1000,
        maxHeight: '80vh',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
      }}>
        <FormSectionTitle label={modalServico.disabled ? 'Visualizar Serviço' : ( modalServico.servico.id > 0 ? 'Editar Serviço' : 'Adicionar Serviço')} />

        <Container sx={{ my: 3, justifyContent: 'center', width: '100%' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControlBase>
                <TextFieldBase
                  type="date"
                  name="inicioFaturamento"
                  label="Início do Faturamento"
                  fullWidth
                  value={formik.values.inicioFaturamento}
                  onChange={formik.handleChange}
                  error={formik.touched.inicioFaturamento && Boolean(formik.errors.inicioFaturamento)}
                  helperText={formik.touched.inicioFaturamento && formik.errors.inicioFaturamento}
                  disabled={modalServico.disabled}
                />
              </FormControlBase>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControlBase>
                <TextFieldBase
                  label="Valor do Serviço (R$)"
                  name="valorServico"
                  fullWidth
                  value={formik.values.valorServico}
                  onChange={formik.handleChange}
                  error={formik.touched.valorServico && Boolean(formik.errors.valorServico)}
                  helperText={formik.touched.valorServico && formik.errors.valorServico}
                  disabled={modalServico.disabled}
                  type="number"
                />
              </FormControlBase>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControlBase>
                <InputLabel id="quantidadeParcelas">Quantidade de Parcelas</InputLabel>
                <SelectBase
                  id="quantidadeParcelas"
                  label="Quantidade de Parcelas"
                  name="quantidadeParcelas"
                  fullWidth
                  value={formik.values.quantidadeParcelas}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.quantidadeParcelas && Boolean(formik.errors.quantidadeParcelas)}
                  helperText={formik.touched.quantidadeParcelas && formik.errors.quantidadeParcelas}
                  disabled={modalServico.disabled}
                >
                  {[...Array(10)].map((_, i) => (
                    <MenuItem key={i + 1} value={i + 1}>{i + 1}</MenuItem>
                  ))}
                </SelectBase>
              </FormControlBase>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControlBase>
                <TextFieldBase
                  label="Valor da Parcela (R$)"
                  fullWidth
                  value={(formik.values.valorServico / formik.values.quantidadeParcelas).toFixed(2) || 0}
                  type="number"
                  disabled
                />
              </FormControlBase>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControlBase>
                <InputLabelSelect  id="tipoServico">Tipo de Serviço</InputLabelSelect>
                <SelectBase 
                  id='tipoServico'
                  name='tipoServico'
                  value={formik.values.tipoServico}
                  onChange={formik.handleChange}
                  error={formik.touched.tipoServico && Boolean(formik.errors.tipoServico)}
                  helperText={formik.touched.tipoServico && formik.errors.tipoServico}
                  disabled={modalServico.disabled}
                  label='Motivo'
                  width='100%'
                >
                  {tiposServicos.map((tipo) => (
                    <MenuItem key={tipo.id} value={tipo.id}>
                      {tipo.tituloTipo}
                    </MenuItem>
                  ))}
                </SelectBase>
              </FormControlBase>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControlBase>
                <TextFieldBase
                  label="Quantidade de Horas"
                  fullWidth
                  value={formik.values.quantidadeHoras}
                  onChange={(event) => {
                    const value = event.target.value;
                    if (/^\d*\.?\d*$/.test(value)) {
                      formik.setFieldValue("quantidadeHoras", value);
                    }
                  }}
                  onBlur={(event) => {
                    const value = event.target.value;
                    formik.setFieldValue(
                      "quantidadeHoras",
                      value ? parseFloat(value).toFixed(2) : "0.00"
                    );
                  }}
                  error={formik.touched.quantidadeHoras && Boolean(formik.errors.quantidadeHoras)}
                  helperText={formik.touched.quantidadeHoras && formik.errors.quantidadeHoras}
                  name="quantidadeHoras"
                  id="quantidadeHoras"
                  inputProps={{
                    inputMode: "decimal",
                    pattern: "[0-9]*[.,]?[0-9]*",
                  }}
                  disabled={modalServico.disabled}
                />
              </FormControlBase>
            </Grid>
            <Grid item xs={12}>
              <FormControlBase>
                <TextFieldBase
                  label="Descrição do Serviço"
                  name="descricaoServico"
                  fullWidth
                  multiline
                  rows={4}
                  value={formik.values.descricaoServico}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.descricaoServico && Boolean(formik.errors.descricaoServico)}
                  helperText={formik.touched.descricaoServico && formik.errors.descricaoServico}
                  disabled={modalServico.disabled}
                />
              </FormControlBase>
            </Grid>
            <Grid item xs={12} md={3} >
              <FormControlLabel 
                control={<Switch 
                  checked={formik.values.credito === 1} 
                  onChange={() => formik.setFieldValue('credito', formik.values.credito === 1 ? 0 : 1)}
                />}
                disabled={modalServico.disabled}
                name="credito" 
                label="Crédito"  
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControlLabel 
                control={<Switch 
                  checked={formik.values.integraFaturamentoAnt === 1} 
                  onChange={() => formik.setFieldValue('integraFaturamentoAnt', formik.values.integraFaturamentoAnt === 1 ? 0 : 1)}
                />} 
                name="integraFaturamentoAnt"  
                label="Integrar no faturamento anterior" 
                disabled={modalServico.disabled} 
              />
            </Grid>
          </Grid>
          
          {modalServico.disabled ? (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 3 }}>
              <Button variant="outlined" onClick={() => setModalServico({ show: false, servico: {} })}>
                Voltar
              </Button>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 3 }}>
              <Button variant="outlined" onClick={() => setModalServico({ show: false, servico: {} })}>
                Cancelar
              </Button>
              <Button variant="contained" onClick={() => formik.handleSubmit()}>
                Salvar
              </Button>
            </Box>
          )}
          
        </Container>
      </Box>
    </Box>
  );
}
