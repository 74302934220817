import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik, FormikProvider } from "formik";
import { format, subDays } from "date-fns";
import CurrencyInput from 'react-currency-input-field';
import * as Yup from "yup";
import {
  Box,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  Button,
  Grid,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { Edit } from "@mui/icons-material";

import { ContratoTab } from "../../contratoTab";
import { ServicoTab } from "../../servicoTab";

import { MyContext } from "../../../../../Context/MyContext";
import { BoxBase, CheckboxBase, CustomTabPanel, FormControlBase, FormSectionBox, FormSectionTitle, SelectBase, TextFieldBase, ButtonBase, InputLabelSelect, tabProps } from "../../../styles";
import { PedidosContext } from "../../../../../Context/PedidosContext";
import { ModalLiberacao } from "../../modalLiberacao";
import Loading from "../../../../../Components/Loading";
import Mensagem from "../../../../../Components/Mensagem";
import ModalNS from "../../../../../Components/ModalNS";
import api from "../../../../../Api/apiAxios";

export function PedidoAcesso({ isShow = false }) {
  const [value, setValue] = useState(0);
  const [clientes, setClientes] = useState([]);
  const [listaNS, setListaNS] = useState([]);
  const [selectedNS, setSelectedNS] = useState(false);
  const [showModalNS, setShowModalNS] = useState(false);
  const [qtdLicencas, setQtdLicencas] = useState(0);
  const [inscricaoCliente, setInscricaoCliente] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showMensagem, setShowMensagem] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const [tipoMensagem, setTipoMensagem] = useState('');
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [versoesAcesso, setVersoesAcesso] = useState([]);

  const nivelUsuario = window.localStorage.getItem('nivelUsuario');  

  const { id } = useParams();
  const { setMensagemGlobal, setTipoMensagemGlobal  } = useContext(MyContext);
  const { 
    distribuidores, 
    modalLiberacao, 
    setModalLiberacao, 
    setDadosTabs, 
    motivosCancelamento, 
    setPedidoLiberado, 
    pedidoLiberado,
    parseNumberCurrency,
    getDistribuidores,
  } = useContext(PedidosContext);

  if (distribuidores.length === 0) {
    getDistribuidores();  
  }

  const navigate = useNavigate();

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const validationSchema = Yup.object().shape({
    tipoPedido: Yup.number()
      .required('Tipo de Pedido é obrigatório')
      .oneOf([0, 1], 'Tipo de Pedido inválido'),
    numeroSerie: Yup.string()
      .required('Número de Série é obrigatório'),
    dataValidade: Yup.date()
      .nullable(),
    distribuidor: Yup.number()
      .required('Revenda é obrigatória'),
    cliente: Yup.number()
      .required('Cliente é obrigatório'),
    razaoSocial: Yup.string()
      .nullable(),
    nomeFantasia: Yup.string()
      .nullable(),
    numeroInscricao: Yup.string()
      .nullable(),
    observacoes: Yup.string()
      .max(1500, 'Observações devem ter no máximo 1500 caracteres')
      .nullable(),
    bancoDeDados: Yup.string()
      .required('Banco de Dados é obrigatório'),
    vertical: Yup.number()
      .required('Vertical é obrigatória'),
    versao: Yup.string()
      .nullable(),
    release: Yup.string()
      .nullable(),
    serverAdicional: Yup.number()
      .typeError('Server Adicional deve ser um número')
      .nullable(),
    equipamentosAdicionais: Yup.number()
      .typeError('Equipamentos Adicionais deve ser um número')
      .nullable(),
    usuariosAgenda: Yup.number()
      .typeError('Usuários Agenda deve ser um número')
      .nullable(),
    modalidadeLocacao: Yup.boolean()
      .required('Este campo é obrigatório'),
    alunos: Yup.boolean()
      .required('Este campo é obrigatório'),
    funcionarios: Yup.boolean()
      .required('Este campo é obrigatório'),
    pacientes: Yup.boolean()
      .required('Este campo é obrigatório'),
    moduloLGPD: Yup.boolean()
      .required('Este campo é obrigatório'),
    ponto: Yup.boolean()
      .required('Este campo é obrigatório'),
    portalTerceiros: Yup.boolean()
      .required('Este campo é obrigatório'),
    contrato: Yup.boolean()
      .required('Este campo é obrigatório'),
    valorLicenca: Yup.string()
      .nullable(),
    valorModuloLGPD: Yup.string()
      .nullable(),
    valorEquipAdicional: Yup.string()
      .nullable(),
    valorServerAdicional: Yup.string()
      .nullable(),
    totalPedido: Yup.string()
      .nullable(),
    valorMensal: Yup.string()
      .nullable(),
    quantidadeParcelas: Yup.number()
      .required('Este campo é obrigatório.'),
    statusPedido: Yup.number()
      .nullable(),
    dataCancelamento: Yup.date().nullable().min(subDays(new Date(), 1), 'A data de cancelamento deve ser a partir de hoje.'),
    motivoCancelamentoOpcao: Yup.number()
      .nullable(),
    motivoCancelamento: Yup.string()
      .nullable(),
  });

  const formik = useFormik({
    initialValues: {
      tipoPedido: null,
      numeroSerie: null,
      dataValidade: null,
      distribuidor: null,
      cliente: null,
      nomeFantasia: '',
      observacoes: '',
      bancoDeDados: null,
      vertical: null,
      versao: 0,
      release: 0,
      versaoFormatada: "0.00.000",
      serverAdicional: 0,
      equipamentosAdicionais: 0,
      usuariosAgenda: 0,
      modalidadeLocacao: false,
      alunos: false,
      funcionarios: false,
      pacientes: false,
      moduloLGPD: false,
      ponto: false,
      portalTerceiros: false,
      contrato: true,
      valorLicenca: "0,00",
      valorModuloLGPD: "0,00",
      valorEquipAdicional: "0,00",
      valorServerAdicional: "0,00",
      totalPedido: "0,00",
      valorMensal: "0,00",
      quantidadeParcelas: 1,
      statusPedido: 0,
      dataCancelamento: null,
      motivoCancelamentoOpcao: 0,
      motivoCancelamento: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSave(values);
    },
  });

  const handleSave = async (values) => {
    setMensagemGlobal('')
    setTipoMensagemGlobal('')
    setShowMensagem(false)

    if (nivelUsuario !== '1') await handleVerificarTotaisClick();
    
    let regPedido = {
      Id: id || 0,
      TipoVertical: formik.values.vertical,
      EquipamentoAdicional: formik.values.equipamentosAdicionais || 0,            
      ValorEquipamentoAdicional: parseNumberCurrency(formik.values.valorEquipAdicional),
      ServerAdicional: formik.values.serverAdicional || 0,            
      ValorServerAdicional: parseNumberCurrency(formik.values.valorServerAdicional),
      AgendaWeb: formik.values.usuariosAgenda > 0 ? 1 : 0,
      UsuariosAgenda: formik.values.usuariosAgenda || 0,
      IntegracaoFuncionarios: formik.values.funcionarios ? 1 : 0,
      IntegracaoPacientes: formik.values.pacientes ? 1 : 0,
      ModuloLGPD: formik.values.moduloLGPD ? 1 : 0,
      ValorModuloLGPD: parseNumberCurrency(formik.values.valorModuloLGPD),
      DistribuidorId: formik.values.distribuidor,    
      TipoPedido: formik.values.tipoPedido,
      ClienteId: formik.values.cliente,    
      NumeroSerie: formik.values.numeroSerie,
      StatusPedido: 0,
      TipoBancoDados: formik.values.bancoDeDados,
      TipoInstalacao: 0,
      VersaoSistema: formik.values.versao?.toString().includes('.') 
      ? formik.values.versao 
      : formik.values.versao.toString().substring(0, 1) + (formik.values.versao.toString().substring(1).length > 0 ? '.' + formik.values.versao.toString().substring(1) : ''),
      VersaoRelease: formik.values.release ? formik.values.release.toString() : '',
      DataPedido: new Date(),
      Observacao: formik.values.observacoes,
      MotivoCancelamento: '',
      NumeroOrdemCompra: '',
      ValorBruto: parseNumberCurrency(formik.values.totalPedido),
      ValorDesconto: 0,
      ValorLiquido: parseNumberCurrency(formik.values.totalPedido),
      ValorMensal: parseNumberCurrency(formik.values.valorMensal),
      ValorLicenca: parseNumberCurrency(formik.values.valorLicenca),
      QuantidadeParcelas: formik.values.quantidadeParcelas, 
      IntegracaoAcesso: 0,
      IntegracaoFolha: 0,
      IntegracaoPonto: formik.values.ponto ? 1 : 0,
      Contrato: formik.values.contrato ? 1 : 0,
      IntegracaoAlunos: formik.values.alunos ? 1 : 0,
      Locacao: formik.values.modalidadeLocacao ? 1 : 0,
      PortalTerceiros: formik.values.portalTerceiros ? 1 : 0,
      DataCancelamento: formik.values.dataCancelamento,
      motivoCancelamentoOpcao: formik.values.motivoCancelamentoOpcao,
      motivoCancelamento: formik.values.motivoCancelamento,
    };
  
    if (formik.values.dataValidade !== null) {
      regPedido = { ...regPedido, DataValidade: formik.values.dataValidade };
    }

    try {
      let ret;

      if (id) {
        ret = await api.put('/BmaAcesso/', JSON.stringify(regPedido), {
          headers: { 'Content-Type': 'application/json' }
        });
      } else {
        ret = await api.post('/BmaAcesso/', JSON.stringify(regPedido), {
          headers: { 'Content-Type': 'application/json' }
        });
      }

      if (!id && ret.data.id > 0) {
        setMensagem('Pedido incluído com sucesso!');
        setTipoMensagem("success");
        setShowMensagem(true);
        navigate(`/viewpedidoacesso/${ret.data.id}`)
      } else if (id) {
        setMensagem('Pedido alterado com sucesso!');
        setTipoMensagem("success");
        setShowMensagem(true);
        navigate(`/viewpedidoacesso/${ret.data.id}`)
      }

    } catch (error) {
      console.error(error);
      setMensagem(error)
      setTipoMensagem("danger");
      setShowMensagem(true);
    }
  };  

  const handleSelecionarNS = () => { 
    setSelectedNS(false)

    if (listaNS.length > 0) setShowModalNS(true) 
  }

  async function handleDistribuidorChange(e) {
    formik.handleChange(e);
    formik.setFieldValue('cliente', 0);
    formik.setFieldValue('nomeFantasia', '');
    formik.setFieldValue('numeroSerie', 0);
    setListaNS([])
  }

  async function handleClienteChange(e) {
    formik.handleChange(e);
    formik.setFieldValue('nomeFantasia', '');
    formik.setFieldValue('numeroSerie', 0);
    setListaNS([])

    const idCliente = e.target.value;

    const response = await api.get(`/Cliente/${idCliente}`);
    formik.setFieldValue('nomeFantasia', response.data.nomeFantasia);
    setInscricaoCliente(response.data.numeroInscricao);

    if (formik.values.tipoPedido === 1) {
      const res = await api.get(`/LicencaAcesso/retornar-ns-cliente?id=${response.data.id}`);
      const qtdRegistros = res.data.length;
      setQtdLicencas(qtdRegistros);
      
      if (qtdRegistros === 1) {
        loadFields(res.data[0], false)
        await atualizarVersaoPorVertical(res.data[0].tipoVertical)
      } else if (qtdRegistros > 1) {
        setListaNS(res.data);
      }
    }
  };

  async function handleVerticalChange(e) {
    formik.handleChange(e);
    await atualizarVersaoPorVertical(e.target.value);
  }

  async function atualizarVersaoPorVertical(tipoVertical) {
    const response = await api.get(`/Versao/retornar-versao-atual?tipoSistema=2&tipoVertical=${tipoVertical}`);

    formik.setFieldValue('versao', response.data.numeroVersao);
    formik.setFieldValue('release', response.data.release);
    formik.setFieldValue('versaoFormatada', response.data.versaoCompletaEditada);
  }

  async function atualizarLicencaPorNS() {
    const response = await api.get(`/LicencaAcesso/retornar-licenca?numeroSerie=${formik.values.numeroSerie}&inscricao=${inscricaoCliente}`);

    loadFields(response.data, false)

    await atualizarVersaoPorVertical(response.data.tipoVertical);
    setSelectedNS(false);
  }

  async function handleVerificarTotaisClick() {
    setIsSaveEnabled(true);

    const dataAtual = new Date();   

    let regPedido = {
      Id: id || 0,
      TipoVertical: formik.values.vertical,
      EquipamentoAdicional: formik.values.equipamentosAdicionais,            
      ValorEquipamentoAdicional: parseNumberCurrency(formik.values.valorEquipAdicional),
      ServerAdicional: formik.values.serverAdicional,            
      ValorServerAdicional: parseNumberCurrency(formik.values.valorServerAdicional),
      AgendaWeb: formik.values.usuariosAgenda > 0 ? 1 : 0,
      UsuariosAgenda: formik.values.usuariosAgenda,
      IntegracaoFuncionarios: formik.values.funcionarios ? 1 : 0,
      IntegracaoPacientes: formik.values.pacientes ? 1 : 0,
      ModuloLGPD: formik.values.moduloLGPD ? 1 : 0,
      ValorModuloLGPD: parseNumberCurrency(formik.values.valorModuloLGPD),
      DistribuidorId: formik.values.distribuidor,    
      TipoPedido: formik.values.tipoPedido,
      ClienteId: formik.values.cliente,    
      NumeroSerie: formik.values.numeroSerie,
      StatusPedido: 0,
      TipoBancoDados: formik.values.bancoDeDados,
      TipoInstalacao: 0,
      VersaoSistema: formik.values.versao.toString().substring(0, 1) + (formik.values.versao.toString().substring(1).length > 0 ? '.' + formik.values.versao.toString().substring(1) : ''),
      VersaoRelease: formik.values.release ? formik.values.release.toString() : '000',
      DataPedido: dataAtual,
      Observacao: formik.values.observacoes,
      MotivoCancelamento: '',
      NumeroOrdemCompra: '',
      ValorBruto: 0,
      ValorDesconto: 0,
      ValorLiquido: 0,
      ValorMensal: 0,
      IntegracaoAcesso: 0,
      IntegracaoFolha: 0,
      IntegracaoPonto: formik.values.ponto ? 1 : 0,
      Contrato: formik.values.contrato ? 1 : 0,
      IntegracaoAlunos: formik.values.alunos ? 1 : 0,
      Locacao: formik.values.modalidadeLocacao ? 1 : 0,
      PortalTerceiros: formik.values.portalTerceiros ? 1 : 0,
    }     
    
    if (formik.values.dataValidade !== '' && formik.values.modalidadeLocacao) {
      regPedido = {...regPedido, DataValidade: formik.values.dataValidade}
    }

    const ret = await api.post("CalculoAcesso/", JSON.stringify(regPedido), { headers: { 'Content-Type': 'application/json'}});

    formik.setFieldValue('valorLicenca', ret.data.valorLicenca)
    formik.setFieldValue('valorEquipAdicional', ret.data.valorEquipamentoAdicional)
    formik.setFieldValue('valorServerAdicional', ret.data.valorServerAdicional)
    formik.setFieldValue('valorModuloLGPD', ret.data.valorModuloLGPD)
    formik.setFieldValue('totalPedido', ret.data.valorBruto)
    formik.setFieldValue('valorMensal', ret.data.valorMensal)
  }

  const handleVersaoChange = (e) => {
    formik.handleChange(e)

    const [mainVersionParts, releaseParts] = e.target.value.split('.').reduce(
      (acc, part, index) => {
        if (index < 2) {
          acc[0].push(part); 
        } else {
          acc[1].push(part);
        }
        return acc;
      },
      [[], []]
    );

    const major = mainVersionParts[0] || '0';
    let minor = mainVersionParts[1] || '0';
  
    minor = parseInt(minor, 10).toString(); 
  
    const mainVersion = `${major}.${minor}`;
    const release = releaseParts.join('') || '000';
  
    formik.setFieldValue('versao', mainVersion);
    formik.setFieldValue('release', release);
  }

  const loadFields = (values, allFields) => {

    if (allFields) {
      formik.setFieldValue('distribuidor', values.distribuidorId)
      formik.setFieldValue('tipoPedido', values.tipoPedido)
      formik.setFieldValue('cliente', values.clienteId)
      formik.setFieldValue('nomeFantasia', values.cliente.nomeFantasia)
      if (values.dataValidade) formik.setFieldValue('dataValidade', format(new Date(values.dataValidade), 'yyyy-MM-dd'));
      formik.setFieldValue('observacoes', values.observacao)
      formik.setFieldValue('valorMensal', values.valorMensal)
      formik.setFieldValue('valorEquipAdicional', values.valorEquipamentoAdicional)
      formik.setFieldValue('valorServerAdicional', values.valorServerAdicional)
      formik.setFieldValue('valorModuloLGPD', values.valorModuloLGPD)
      formik.setFieldValue('valorLicenca', values.valorLicenca)
      formik.setFieldValue('totalPedido', values.valorBruto)
      formik.setFieldValue('quantidadeParcelas', values.quantidadeParcelas)
      formik.setFieldValue('statusPedido', values.statusPedido)
      formik.setFieldValue('razaoSocial', values.cliente.razaoSocial)
      formik.setFieldValue('numeroInscricao', values.cliente.numeroInscricao)
    }

    formik.setFieldValue('numeroSerie', values.numeroSerie)
    formik.setFieldValue('bancoDeDados', values.tipoBancoDados)
    formik.setFieldValue('vertical', values.tipoVertical)
    formik.setFieldValue('versao', values.versaoSistema || values.versao)
    formik.setFieldValue('release', values.versaoRelease)
    formik.setFieldValue('usuariosAgenda', values.usuariosAgenda)
    formik.setFieldValue('equipamentosAdicionais', values.equipamentoAdicional)
    formik.setFieldValue('serverAdicional', values.serverAdicional)
    formik.setFieldValue('contrato', values.temContrato === 1 || values.contrato === 1)
    formik.setFieldValue('ponto', values.integracaoPonto === 1)
    formik.setFieldValue('moduloLGPD', values.moduloLGPD === 1)
    formik.setFieldValue('funcionarios', values.integracaoFuncionarios === 1)
    formik.setFieldValue('pacientes', values.integracaoPacientes === 1)
    formik.setFieldValue('alunos', values.integracaoAlunos === 1)
    formik.setFieldValue('modalidadeLocacao', values.locacao === 1)
    formik.setFieldValue('portalTerceiros', values.portalTerceiros === 1)
    formik.setFieldValue('motivoCancelamentoOpcao', values.motivoCancelamentoOpcao)
    formik.setFieldValue('motivoCancelamento', values.motivoCancelamento)

    formik.setFieldValue(
      'versaoFormatada',
      (() => {
        let versao = values.versaoSistema || values.versao;
        const release = values.versaoRelease || values.release;

        if (versao.includes('.') && versao?.split('.').length === 2) {
          const [major, minor] = versao.split('.');
          versao = `${major}.${minor.padStart(2, '0')}`;
        }
    
        return `${versao}.${release}`;
      })()
    );

    if (values.dataCancelamento) formik.setFieldValue('dataCancelamento', format(new Date(values.dataCancelamento), 'yyyy-MM-dd'))
    
  }

  async function loadVersoesAcesso() {
    try {
      const response = await api.get(`/Versao/versao-por-produto?tipoSistema=2&tipoVertical=${formik.values.vertical}`)

      setVersoesAcesso(response.data);
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (formik.values.statusPedido === 0) {
      setPedidoLiberado(false);
    }
  }, [])

  useEffect(() => {
    const getClientes = async (id) => {
      if (!id) return;
  
      try {
        const { data } = await api.get(`/Cliente/${id}/A`);
        
        const registrosFiltrados = data
          .filter((cliente) => cliente.statusEmpresa === 1)
          .sort((a, b) => a.razaoSocial.localeCompare(b.razaoSocial));
  
        setClientes(registrosFiltrados);
      } catch (error) {
        console.error("Erro ao buscar clientes:", error);
      }
    };
  
    getClientes(formik.values.distribuidor);
  }, [formik.values.distribuidor]);

  useEffect(() => {
    if (selectedNS) atualizarLicencaPorNS();
  }, [selectedNS])

  useEffect(() => {
    loadVersoesAcesso();
  }, [formik.values.vertical])

  useEffect(() => {
   const loadData = async () => {
    if (!id) return;

    try {
      setIsLoading(true)
      const response = await api.get(`/BmaAcesso/${id}`);

      loadFields(response.data, true)
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
   }

   loadData();
  }, [id])

  useEffect(() => {
    if (formik.isSubmitting && formik.errors) {
      console.log(formik.errors)
      setMensagem('Preencha os campos obrigatórios.')
      setTipoMensagem('danger');
      setShowMensagem(true)
    }
  }, [formik.errors])

  useEffect(() => {
     setDadosTabs({ 
      distribuidor: formik.values.distribuidor, 
      cliente: formik.values.cliente, 
      nomeFantasia: formik.values.nomeFantasia, 
      numeroSerie: formik.values.numeroSerie })
  }, [
    formik.values.distribuidor,
    formik.values.cliente,
    formik.values.nomeFantasia,
    formik.values.numeroSerie
  ])

  if (isLoading) return <Loading />
  
  return (
    <Box>
      {showMensagem && <Mensagem mensagem={mensagem} setMensagem={setMensagem} variant={tipoMensagem}/>}
      {showModalNS && formik.values.tipoPedido === 1 && listaNS.length > 0 &&
        <ModalNS 
          modalNS={showModalNS} 
          setModalNS={setShowModalNS} 
          numeroSerie={formik.values.numeroSerie} 
          setNumeroSerie={(value) => formik.setFieldValue('numeroSerie', value)} 
          titleModal={formik.values.nomeFantasia} 
          listaNS={listaNS}
          setNSSelecionada={setSelectedNS}
        />
      }

      {modalLiberacao.show && 
        <ModalLiberacao 
          pedido={modalLiberacao.pedido} 
        />
      }
      <Container sx={{ py: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Tabs
            sx={{ backgroundColor: '#f5f5f5', boxShadow: '0 -1px 4px 1px rgba(0, 0, 0, 0.1)' }}
            value={value}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="Pedido" {...tabProps(0)} />
            <Tab label="Contrato" {...tabProps(1)} disabled={!formik.values.contrato || !id} />
            <Tab label="Serviço" {...tabProps(2)} disabled={!id}/>
          </Tabs>
          <Typography component='h1' variant='h5' sx={{ margin: '0.6rem 13%', fontWeight: 400, color: '#a3a3a3' }}>Pedido - Acesso</Typography>
        </Box>
        <Box>
          <Paper sx={{ backgroundColor: '#F7F8F8', width: '70rem'}} elevation={3}>
            <CustomTabPanel value={value} index={0}>
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end', pr: 1 }}>
                {isShow && formik.values.statusPedido === 0 &&
                  <Button
                    variant='text'
                    startIcon={<DriveFolderUploadIcon />}
                    color='success'
                    onClick={() => setModalLiberacao({ show: true, pedido: { id: id, cliente: { razaoSocial: formik.values.razaoSocial, numeroInscricao: formik.values.numeroInscricao }, contrato: formik.values.contrato, tipoInstalacao: formik.values.tipoInstalacao }} )} 
                    disabled={nivelUsuario !== '1' || pedidoLiberado}
                  >
                    Liberar Pedido
                  </Button>
                }
                {isShow && formik.values.statusPedido === 0 && (nivelUsuario === '1' || nivelUsuario === '3') &&
                  <Tooltip title='Editar'>
                    <IconButton onClick={() => navigate(`/pedidoacesso/${id}`)}>
                      <Edit fontSize='small' />
                    </IconButton>
                  </Tooltip>
                }
              </Box>
              <Box 
                component='form'
                onSubmit={formik.handleSubmit}
                sx={{display: 'flex', flexDirection: 'column', gap: 1}}
              >
                <FormSectionBox>
                  <FormSectionTitle label="Dados do Pedido" />
                  <Container sx={{display: 'flex', flexDirection: 'column', my: 3, justifyContent: 'center'}}>
                    <BoxBase>
                      <FormControlBase>
                        <InputLabelSelect id="tipoPedido">Tipo de Pedido</InputLabelSelect>
                        <SelectBase 
                          id="tipoPedido"
                          name="tipoPedido"
                          label='Tipo de Pedido'
                          value={formik.values.tipoPedido}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.tipoPedido && Boolean(formik.errors.tipoPedido)}
                          helperText={formik.touched.name && formik.errors.name}
                          isShow={isShow}
                          width='20rem'
                        >
                          <MenuItem value={0}>Licença Nova</MenuItem>
                          <MenuItem value={1}>Atualização</MenuItem>
                          <MenuItem value={2}>Simulação</MenuItem>
                        </SelectBase>
                      </FormControlBase>

                      <FormControlBase>
                        <InputLabelSelect id="distribuidor">Revenda</InputLabelSelect>
                        <SelectBase 
                          id="distribuidor" 
                          label="Revenda"
                          name="distribuidor"
                          value={formik.values.distribuidor}
                          onChange={(e) => handleDistribuidorChange(e)}
                          onBlur={formik.handleBlur}
                          error={formik.touched.distribuidor && Boolean(formik.errors.distribuidor)}
                          helperText={formik.touched.distribuidor && formik.errors.distribuidor}
                          isShow={isShow}
                          width='20rem'
                        >
                          {distribuidores.map((distribuidor) => (
                            <MenuItem
                              key={distribuidor.id}
                              value={distribuidor.id}
                            >
                              {distribuidor.razaoSocial}
                            </MenuItem>
                          ))}
                        </SelectBase>
                      </FormControlBase>
                      <FormControlBase>
                        <InputLabelSelect id="cliente">Cliente</InputLabelSelect>
                        <SelectBase 
                          id="cliente" 
                          label="Cliente"
                          name="cliente"
                          value={formik.values.cliente}
                          onChange={(e) => handleClienteChange(e)}
                          onBlur={formik.handleBlur}
                          error={formik.touched.cliente && Boolean(formik.errors.cliente)}
                          helperText={formik.touched.cliente && formik.errors.cliente}
                          isShow={isShow}
                          width='20rem'
                        >
                          {clientes.map((cliente) => (
                            <MenuItem
                              key={cliente.id}
                              value={cliente.id}
                            >
                              {cliente.razaoSocial} - {cliente.numeroInscricao}
                            </MenuItem>
                          ))}
                        </SelectBase>
                      </FormControlBase>
                    </BoxBase>
                    
                    <BoxBase> 
                      <FormControlBase>
                        <TextFieldBase 
                          label="Nome Fantasia"
                          value={formik.values.nomeFantasia}
                          width='31rem'
                          disabled
                        />
                      </FormControlBase>

                      <FormControlBase>
                        <TextFieldBase 
                          label="Número Série"
                          name="numeroSerie"
                          value={formik.values.numeroSerie}
                          onChange={formik.handleChange}
                          error={formik.touched.numeroSerie && Boolean(formik.errors.numeroSerie)}
                          helperText={formik.touched.numeroSerie && formik.errors.numeroSerie}
                          width='20rem'
                          textAlign='center'
                          disabled={nivelUsuario !== '1' || isShow}
                        />
                        <ButtonBase 
                          variant="contained"
                          onClick={() => handleSelecionarNS()} 
                          sx={{ marginLeft: -0.25, width: '10rem', fontSize: '0.75rem', maxHeight: '3.5rem' }}
                          disabled={formik.values.tipoPedido === 0 || qtdLicencas < 2 || isShow}
                        >
                          Selecionar NS
                        </ButtonBase>
                      </FormControlBase>
                    </BoxBase>

                      <FormControlBase>
                        <TextFieldBase 
                          label="Observações"
                          name="observacoes"
                          value={formik.values.observacoes}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.observacoes && Boolean(formik.errors.observacoes)}
                          helperText={formik.touched.observacoes && formik.errors.observacoes}
                          width='100%'  
                          multiline 
                          rows={4}
                          disabled={isShow}
                        />
                      </FormControlBase>

                      {(formik.values.statusPedido === 2 || formik.values.dataCancelamento !== null) &&
                        <>
                          <br />
                          <BoxBase>
                            <FormControlBase>
                              <TextFieldBase 
                                label="Data do Cancelamento" 
                                name="dataCancelamento"
                                value={formik.values.dataCancelamento}
                                type="date"
                                onChange={formik.handleChange}
                                onBlur={formik.onBlur}
                                error={formik.touched.dataCancelamento && Boolean(formik.errors.dataCancelamento)}
                                helperText={formik.touched.dataCancelamento && formik.errors.dataCancelamento}
                                width='20rem'
                                disabled={isShow}
                              />
                            </FormControlBase>

                            <FormControlBase>
                              <InputLabelSelect id="motivoCancelamentoOpcao">Motivo do Cancelamento</InputLabelSelect>
                              <SelectBase 
                                id='motivoCancelamentoOpcao'
                                name='motivoCancelamentoOpcao'
                                disabled={isShow}
                                onChange={formik.handleChange}
                                onBlur={formik.onBlur}
                                error={formik.touched.motivoCancelamentoOpcao && Boolean(formik.errors.motivoCancelamentoOpcao)}
                                helperText={formik.touched.motivoCancelamentoOpcao && formik.errors.motivoCancelamentoOpcao}
                                label='Motivo do Cancelamento'
                                value={formik.values.motivoCancelamentoOpcao}
                                width='41rem'
                                margin='0 0 0 -5px'
                              >
                                {motivosCancelamento.map((motivo) => (
                                  <MenuItem key={motivo.id} value={motivo.id}>
                                    {motivo.descricaoMotivo}
                                  </MenuItem>
                                ))}
                              </SelectBase>
                            </FormControlBase>
                          </BoxBase>

                          <br />

                          <FormControlBase>
                            <TextFieldBase 
                              label="Detalhes do Cancelamento"
                              name="motivoCancelamento"
                              onChange={formik.handleChange}
                                onBlur={formik.onBlur}
                                error={formik.touched.motivoCancelamento && Boolean(formik.errors.motivoCancelamento)}
                                helperText={formik.touched.motivoCancelamento && formik.errors.motivoCancelamento}
                              value={formik.values.motivoCancelamento}
                              disabled={isShow}
                              width='100%'  
                              multiline 
                              rows={4}
                            />
                          </FormControlBase>
                        </>
                        }
                  </Container>
                </FormSectionBox>


                <FormSectionBox>
                  <FormSectionTitle label="Dados da Licença" />
                  <Container sx={{display: 'flex', flexDirection: 'column', my: 3}}>
                    <BoxBase>
                      <FormControlBase>
                        <InputLabelSelect id="bancoDeDados">Banco de Dados</InputLabelSelect>
                        <SelectBase 
                          id="bancoDeDados" 
                          label="Banco de Dados"
                          name="bancoDeDados"
                          value={formik.values.bancoDeDados}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.bancoDeDados && Boolean(formik.errors.bancoDeDados)}
                          helperText={formik.touched.bancoDeDados && formik.errors.bancoDeDados}
                          isShow={isShow}
                          width='20rem'
                        >
                          <MenuItem value={1}>MySql</MenuItem>
                          <MenuItem value={2}>SqlServer</MenuItem>
                          <MenuItem value={3}>Oracle</MenuItem>
                        </SelectBase>
                      </FormControlBase>

                      <FormControlBase>
                        <InputLabelSelect  id="vertical">Vertical</InputLabelSelect>
                        <SelectBase 
                          id="vertical" 
                          label="Vertical"
                          name="vertical"
                          value={formik.values.vertical}
                          onChange={handleVerticalChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.vertical && Boolean(formik.errors.vertical)}
                          helperText={formik.touched.vertical && formik.errors.vertical}
                          width={nivelUsuario === "1" ? '10rem' : '20rem'}
                          isShow={isShow}
                        >
                            <MenuItem value={1}>Empresa</MenuItem>
                            <MenuItem value={2}>Refeitório</MenuItem>
                            <MenuItem value={3}>Academia</MenuItem>
                            <MenuItem value={4}>Condomínio</MenuItem>
                            <MenuItem value={5}>Hospital</MenuItem>
                            <MenuItem value={6}>Escola</MenuItem>
                        </SelectBase>
                      </FormControlBase>

                      <FormControlBase>
                        <TextFieldBase
                          select
                          id="versao"
                          name="versaoFormatada"
                          label="Versão"
                          value={formik.values.versaoFormatada}
                          onChange={(e) => handleVersaoChange(e)}
                          width={nivelUsuario === "1" ? '10rem' : '20rem'}
                          textAlign="center"
                          marginLeft={nivelUsuario === "1" ? -3 : 0}
                          disabled={nivelUsuario !== "1" || isShow}
                        >
                          {versoesAcesso.map((item) => {
                            return (
                              <MenuItem key={item.versaoCompletaEditada} value={item.versaoCompletaEditada}>
                                {item.versaoCompletaEditada}
                              </MenuItem>
                            )
                          })}
                        </TextFieldBase>
                      </FormControlBase>

                      {nivelUsuario === "1" && 
                        <FormControlBase>
                          <TextFieldBase 
                            label="Data de Validade" 
                            name="dataValidade"
                            value={formik.values.dataValidade}
                            type="date"
                            onChange={formik.handleChange}
                            onBlur={formik.onBlur}
                            error={formik.touched.dataValidade && Boolean(formik.errors.dataValidade)}
                            helperText={formik.touched.dataValidade && formik.errors.dataValidade}
                            width='20rem'
                            disabled={isShow}
                          />
                        </FormControlBase>
                      }
                    </BoxBase>

                    <BoxBase>
                      <FormControl>
                        <TextFieldBase 
                          label="Server Adicional"
                          name="serverAdicional"
                          value={formik.values.serverAdicional}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.serverAdicional && Boolean(formik.errors.serverAdicional)}
                          helperText={formik.touched.serverAdicional && formik.errors.serverAdicional}
                          width='20rem'
                          disabled={isShow}
                          textAlign='center'
                        />
                      </FormControl>

                      <FormControlBase>
                        <TextFieldBase 
                          id="equipamentosAdicionais" 
                          label="Equipamentos Adicionais"
                          name="equipamentosAdicionais"
                          value={formik.values.equipamentosAdicionais}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.equipamentosAdicionais && Boolean(formik.errors.equipamentosAdicionais)}
                          helperText={formik.touched.equipamentosAdicionais && formik.errors.equipamentosAdicionais}
                          width='20.5rem'
                          disabled={isShow}
                          textAlign='center'
                        />
                      </FormControlBase>

                      <FormControlBase>
                        <TextFieldBase 
                          label="Usuários Agenda"
                          name="usuariosAgenda"
                          value={formik.values.usuariosAgenda}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.usuariosAgenda && Boolean(formik.errors.usuariosAgenda)}
                          helperText={formik.touched.usuariosAgenda && formik.errors.usuariosAgenda}
                          width='20rem'
                          disabled={isShow}
                          textAlign='center'
                        />
                      </FormControlBase>
                    </BoxBase>

                    <FormikProvider value={formik}>
                      <Grid container spacing={2} sx={{ pb: 2, pl: 4, mt: 1, display: 'flex' }}>
                        <Grid item xs={3}>
                          <CheckboxBase
                            name="contrato"
                            checked={formik.values.contrato}
                            onChange={formik.handleChange}
                            disabled={isShow}
                            label="Contrato"
                          /> 
                        </Grid>
                        <Grid item xs={3}>
                          <CheckboxBase
                            name="modalidadeLocacao"
                            checked={formik.values.modalidadeLocacao}
                            onChange={formik.handleChange}
                            disabled={isShow}
                            label="Modalidade Locação"
                          />                        
                        </Grid>
                        <Grid item xs={3}>
                          <CheckboxBase
                            name="moduloLGPD"
                            checked={formik.values.moduloLGPD}
                            onChange={formik.handleChange}
                            disabled={isShow}
                            label="Módulo LGPD"
                          /> 
                        </Grid>
                        <Grid item xs={3}>
                          <CheckboxBase
                            name="portalTerceiros"
                            checked={formik.values.portalTerceiros}
                            onChange={formik.handleChange}
                            disabled={isShow}
                            label="Portal Terceiros"
                          /> 
                        </Grid>
                      </Grid>
                      <Typography variant="overline" sx={{ ml: 4, mt: 0.2, mb: 2, borderBottom: '1px solid rgb(19, 35, 80)', textAlign: 'center' }}>Integrações</Typography>
                      <Grid container spacing={2} sx={{ pb: 2, pl: 4, display: 'flex' }}>
                        <Grid item xs={3}>
                          <CheckboxBase
                            name="alunos"
                            checked={formik.values.alunos}
                            onChange={formik.handleChange}
                            disabled={isShow}
                            label="Alunos"
                          />  
                        </Grid>
                        <Grid item xs={3}>
                          <CheckboxBase
                            name="funcionarios"
                            checked={formik.values.funcionarios}
                            onChange={formik.handleChange}
                            disabled={isShow}
                            label="Funcionários"
                          /> 
                        </Grid>
                        <Grid item xs={3}>
                          <CheckboxBase
                            name="pacientes"
                            checked={formik.values.pacientes}
                            onChange={formik.handleChange}
                            disabled={isShow}
                            label="Pacientes"
                          /> 
                        </Grid>
                        
                        <Grid item xs={3}>
                          <CheckboxBase
                            name="ponto"
                            checked={formik.values.ponto}
                            onChange={formik.handleChange}
                            disabled={isShow}
                            label="Ponto"
                          /> 
                        </Grid>                       
                      </Grid>
                    </FormikProvider>
                  </Container>
                </FormSectionBox>
                
                <FormSectionBox>
                  <FormSectionTitle label="Totais" />
                  <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                    <Grid container spacing={2} sx={{ pt: 2, pb: 4, px: 4 }}>
                      <Grid item xs={3}>
                        <FormControl>
                          <CurrencyInput
                            customInput={TextFieldBase}
                            label="Valor da Licença"
                            name="valorLicenca"
                            value={formik.values.valorLicenca}
                            onValueChange={(value) => formik.setFieldValue('valorLicenca', value)}
                            onBlur={formik.handleBlur}
                            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                            disabled={nivelUsuario !== '1' || isShow}
                            textAlign="center"
                            decimalScale={2}
                            decimalsLimit={2}
                            mb={1.5}
                          />
                          <CurrencyInput
                            customInput={TextFieldBase}
                            label="Valor Módulo LGPD"
                            name="valorModuloLGPD"
                            value={formik.values.valorModuloLGPD}
                            onValueChange={(value) => formik.setFieldValue('valorModuloLGPD', value)}
                            onBlur={formik.handleBlur}
                            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                            disabled={nivelUsuario !== '1' || isShow}
                            textAlign="center"
                            decimalScale={2}
                            decimalsLimit={2}
                            mb={1.5}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl>
                          <CurrencyInput
                            customInput={TextFieldBase}
                            label="Valor Equip. Adicional"
                            name="valorEquipAdicional"
                            value={formik.values.valorEquipAdicional}
                            onValueChange={(value) => formik.setFieldValue('valorEquipAdicional', value)}
                            onBlur={formik.handleBlur}
                            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                            disabled={nivelUsuario !== '1' || isShow}
                            textAlign="center"
                            decimalScale={2}
                            decimalsLimit={2}
                            mb={1.5}
                          />
                          <CurrencyInput
                            customInput={TextFieldBase}
                            label="Valor Server Adicional"
                            name="valorServerAdicional"
                            value={formik.values.valorServerAdicional}
                            onValueChange={(value) => formik.setFieldValue('valorServerAdicional', value)}
                            onBlur={formik.handleBlur}
                            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                            disabled={nivelUsuario !== '1' || isShow}
                            textAlign="center"
                            decimalScale={2}
                            decimalsLimit={2}
                            mb={1.5}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl>
                          <CurrencyInput
                            customInput={TextFieldBase}
                            label="Total Pedido"
                            name="totalPedido"
                            value={formik.values.totalPedido}
                            onValueChange={(value) => formik.setFieldValue('totalPedido', value)}
                            onBlur={formik.handleBlur}
                            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                            disabled={nivelUsuario !== '1' || isShow}
                            textAlign="center"
                            decimalScale={2}
                            decimalsLimit={2}
                            mb={1.5}
                          />
                          <CurrencyInput
                            customInput={TextFieldBase}
                            label="Valor Mensal"
                            name="valorMensal"
                            value={formik.values.valorMensal}
                            onValueChange={(value) => formik.setFieldValue('valorMensal', value)}
                            onBlur={formik.handleBlur}
                            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                            disabled={nivelUsuario !== '1' || isShow}
                            textAlign="center"
                            decimalScale={2}
                            decimalsLimit={2}
                            mb={1.5}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={3} sx={{display: 'flex', justifyContent: 'center', alignItems: 'end', pb: 2}}>
                        <ButtonBase variant="outlined" onClick={() => handleVerificarTotaisClick()} startIcon={<AttachMoneyIcon />} disabled={isShow}>
                          Verificar Totais
                        </ButtonBase>
                      </Grid>
                    </Grid>

                    { (nivelUsuario === '1' || isShow) &&
                      <FormControlBase>
                          <InputLabel sx={{margin: '0 0 0 2rem'}} id="quantidadeParcelas">Quantidade de Parcelas</InputLabel>
                          <SelectBase 
                            id="quantidadeParcelas" 
                            label="Quantidade de Parcelas"
                            name="quantidadeParcelas"
                            value={formik.values.quantidadeParcelas}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.quantidadeParcelas && Boolean(formik.errors.quantidadeParcelas)}
                            helperText={formik.touched.quantidadeParcelas && formik.errors.quantidadeParcelas}
                            width='14rem'
                            margin='0 0 0 2rem'
                            isShow={isShow}
                          >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={7}>7</MenuItem>
                            <MenuItem value={8}>8</MenuItem>
                            <MenuItem value={9}>9</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                          </SelectBase>
                        </FormControlBase>
                      }
                  </Box>
                </FormSectionBox>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
                  {isShow || formik.values.tipoPedido === 2 ? (
                    <ButtonBase variant="outlined" onClick={() => navigate('/pedidos')}>
                      Voltar
                    </ButtonBase>
                  ) : (
                    <>
                      <ButtonBase variant="outlined" onClick={() => navigate('/pedidos')}>
                        Cancelar
                      </ButtonBase>
                      <ButtonBase type="submit" variant="contained" disabled={!isSaveEnabled}>
                        Salvar
                      </ButtonBase>	
                    </>
                  )}
                </Box>
                
              </Box>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
              <ContratoTab isShow={isShow} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <ServicoTab isShow={isShow || nivelUsuario !== "1"} />
            </CustomTabPanel>
          </Paper>
        </Box>
      </Container>
    </Box>
  );
}
