import { HashRouter } from "react-router-dom";
import { MyStorage } from "./Context/MyContext";
import Menu from "./Forms/Menu";
import { Router } from "./Components/Router";
import { DashboardData } from "./Context/DashboardAlternadoContext";
import { PedidosData } from "./Context/PedidosContext";

function App() {
  return (
    <HashRouter>
      <PedidosData>
        <MyStorage>
            <DashboardData>
              <Menu />
              <Router />
          </DashboardData>
        </MyStorage> 
      </PedidosData>  
    </HashRouter>    
  );
}

export default App;
