import { useContext, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { 
  styled, 
  Table, 
  TableBody, 
  TableCell, 
  tableCellClasses, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Pagination,  
  Box
} from '@mui/material';

import { TableItemName } from '../../../../../Components/TableItemName';
import { ActionButtons } from '../../actionButtons';
import { ModalLiberacao } from '../../modalLiberacao';
import { ModalCancelamentoAcesso } from '../modalCancelamento';
import { PedidosContext } from '../../../../../Context/PedidosContext';
import { MyContext } from '../../../../../Context/MyContext';
import StatusPedido from '../../../../../Components/StatusPedido';
import Loading from '../../../../../Components/Loading';

export function AcessoIndex() {
  const [page, setPage] = useState(1);

  const { itensPorPagina } = useContext(MyContext)
  const { fetchPedidosAcesso, pedidosAcesso, modalLiberacao, modalCancelamento, isLoading, setIsLoading } = useContext(PedidosContext);

  const paginatedPedidos = pedidosAcesso.slice((page - 1) * itensPorPagina, page * itensPorPagina);

  useEffect(() => {
    setIsLoading(true);
    fetchPedidosAcesso()
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false));
  }, []);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  if (isLoading) return <Loading />;
  
  return (
    <Box sx={{ width: '100%'}}>
      {modalLiberacao.show && 
        <ModalLiberacao 
          pedido={modalLiberacao.pedido} 
        />
      }

      {modalCancelamento.show && 
        <ModalCancelamentoAcesso 
          pedido={modalCancelamento.pedido} 
        />
      }

      <TableContainer style={{ minHeight: '60vh', paddingBottom: '1rem' }}>
        <Table aria-label="Listagem de Pedidos de Acesso">
          <TableHead>
            <TableRow>
              <StyledTableCell>Revenda</StyledTableCell>
              <StyledTableCell>Cliente</StyledTableCell>
              <StyledTableCell align='center'>Vertical</StyledTableCell>
              <StyledTableCell align='center'>Versão</StyledTableCell>
              <StyledTableCell align='center'>NS</StyledTableCell>
              <StyledTableCell align='center'>Equipe Ad.</StyledTableCell>
              <StyledTableCell align='center'>Contrato</StyledTableCell>
              <StyledTableCell align='center'>Data Pedido</StyledTableCell>
              <StyledTableCell align='center'>Data Lib.</StyledTableCell>
              <StyledTableCell align='center'>Status</StyledTableCell>
              <StyledTableCell align='center'>Ações</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedPedidos.map((pedido) => (
              <StyledTableRow key={pedido.id}>
                <StyledTableCell><TableItemName title={pedido.distribuidor.nomeFantasia} /></StyledTableCell>
                <StyledTableCell><TableItemName title={pedido.cliente.razaoSocial} /></StyledTableCell>
                <StyledTableCell align='center'> 
                  {pedido.tipoVertical === 1 && 'Empresa'}
                  {pedido.tipoVertical === 2 && 'Refeitório'}
                  {pedido.tipoVertical === 3 && 'Academia'}
                  {pedido.tipoVertical === 4 && 'Condomínio'}
                  {pedido.tipoVertical === 5 && 'Hospital'}
                  {pedido.tipoVertical === 6 && 'Escola'}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {(() => {
                    const [major, minor] = pedido.versaoSistema.split('.');
                    const formattedMinor = minor.length === 1 ? minor + '0' : minor;
                    const formattedVersion = `${major}.${formattedMinor}`;

                    return `${formattedVersion}${pedido.versaoRelease ? '.' + pedido.versaoRelease : '.000'}`;
                  })()}
                </StyledTableCell>
                <StyledTableCell align='center'>{pedido.numeroSerie}</StyledTableCell>
                <StyledTableCell align='center'>{pedido.equipamentoAdicional < 10 ? '00' + pedido.equipamentoAdicional : '0' + pedido.equipamentoAdicional}</StyledTableCell>
                <StyledTableCell align='center'>{pedido.contrato === 1 ? 'Sim' : 'Não'}</StyledTableCell>
                <StyledTableCell align='center'>{format(pedido.dataPedido, 'dd/MM/yyyy')}</StyledTableCell>
                <StyledTableCell align='center'>{pedido.dataLiberacao ? format(pedido.dataLiberacao, 'dd/MM/yyyy') : (pedido.statusPedido === 2 ? 'Em Análise' : '*****')}</StyledTableCell>
                <StyledTableCell align='center'><StatusPedido status={pedido.statusPedido} isPedido/></StyledTableCell>
                <StyledTableCell align='center'>
                  <ActionButtons pedido={pedido} />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        count={Math.ceil(pedidosAcesso.length / itensPorPagina)}
        page={page}
        onChange={handleChangePage}
        color="primary"
        sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
      />
    </Box>
  );
}

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 'bold',
    backgroundColor: theme.palette.action.hover,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '&:hover': {
    backgroundColor: 'rgb(19, 35, 80, 0.02)',
  },
}));
