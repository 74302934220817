import { useContext, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { 
  styled, 
  Table, 
  TableBody, 
  TableCell, 
  tableCellClasses, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Pagination,
  Box
} from '@mui/material';

import { TableItemName } from '../../../../../Components/TableItemName';
import { ActionButtons } from '../../actionButtons';
import { ModalLiberacao } from '../../modalLiberacao';
import { PedidosContext } from '../../../../../Context/PedidosContext';
import { ModalCancelamento } from '../../modalCancelamento';
import { MyContext } from '../../../../../Context/MyContext';
import TipoInstalacao from '../../../../../Components/TipoInstalacao';
import StatusPedido from '../../../../../Components/StatusPedido';
import Loading from '../../../../../Components/Loading';

export function FolhaIndex() {
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);

  const { itensPorPagina } = useContext(MyContext)
  const { modalLiberacao, fetchPedidosFolha, pedidosFolha, modalCancelamento } = useContext(PedidosContext);

  const paginatedPedidos = pedidosFolha.slice((page - 1) * itensPorPagina, page * itensPorPagina);

  useEffect(() => {
    setIsLoading(true);
    fetchPedidosFolha()
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false));
  }, []);

  const handleChangePage = (event, value) => {
    setPage(value);
  };
  
  if (isLoading) return <Loading />;
  
  return (
    <Box sx={{ width: '100%' }}>
      {modalLiberacao.show && 
        <ModalLiberacao 
          pedido={modalLiberacao.pedido} 
        />
      }

      {modalCancelamento.show && 
        <ModalCancelamento
          pedido={modalCancelamento.pedido} 
        />
      }

      <TableContainer sx={{width: '100%', minHeight: '60vh', paddingBottom: '1rem'}}>
        <Table aria-label="Listagem de Pedidos de Acesso" sx={{width: '100%'}}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Revenda</StyledTableCell>
              <StyledTableCell>Cliente</StyledTableCell>
              <StyledTableCell align='center'>Versão</StyledTableCell>
              <StyledTableCell align='center'>NS</StyledTableCell>
              <StyledTableCell align='center'>Instalação</StyledTableCell>
              <StyledTableCell align='center'>Faixa</StyledTableCell>
              <StyledTableCell align='center'>Data Pedido</StyledTableCell>
              <StyledTableCell align='center'>Data Lib.</StyledTableCell>
              <StyledTableCell align='center'>Status</StyledTableCell>
              <StyledTableCell align='center'>Ações</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedPedidos.map((pedido) => (
              <StyledTableRow key={pedido.id}>
                <StyledTableCell><TableItemName title={pedido.distribuidor.nomeFantasia} /></StyledTableCell>
                <StyledTableCell><TableItemName title={pedido.cliente.razaoSocial} /></StyledTableCell>
                <StyledTableCell align='center'>{pedido.versaoSistema}{pedido.versaoRelease ? '.' + pedido.versaoRelease : ''}</StyledTableCell>
                <StyledTableCell align='center'>{pedido.numeroSerie}</StyledTableCell>
                <StyledTableCell align='center'><TipoInstalacao tipoInstalacao={pedido.tipoInstalacao} isPedido/></StyledTableCell>
                <StyledTableCell align='center'>{pedido.faixaFuncionarios}</StyledTableCell>
                <StyledTableCell align='center'>{format(pedido.dataPedido, 'dd/MM/yyyy')}</StyledTableCell>
                <StyledTableCell align='center'>{pedido.dataLiberacao ? format(pedido.dataLiberacao, 'dd/MM/yyyy') : (pedido.statusPedido === 2 ? 'Em Análise' : '*****')}</StyledTableCell>
                <StyledTableCell align='center'><StatusPedido status={pedido.statusPedido} isPedido /></StyledTableCell>
                <StyledTableCell align='center'><ActionButtons pedido={pedido} /></StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        count={Math.ceil(pedidosFolha.length / itensPorPagina)}
        page={page}
        onChange={handleChangePage}
        color="primary"
        sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
      />
    </Box>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 'bold',
    backgroundColor: theme.palette.action.hover,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '&:hover': {
    backgroundColor: 'rgb(19, 35, 80, 0.02)',
  },
}));
